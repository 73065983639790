// VC Shortcodes

.vc_custom_1571808150594 {
  margin-top: -180px !important;
}
.vc_custom_1584905712173 {
  background-color: #fbba18 !important;
}
.vc_custom_1582701221621 {
  padding-right: 7% !important;
}
.vc_custom_1582701231908 {
  margin-top: -40px !important;
}
.vc_custom_1571916435514 {
  margin-top: -90px !important;
  margin-bottom: 20px !important;
}
.vc_custom_1582700174357 {
  padding-right: 7% !important;
}
.vc_custom_1582699088137 {
  margin-top: -40px !important;
}
.vc_custom_1584874008275 {
  margin-top: -90px !important;
  margin-bottom: 20px !important;
}
.vc_custom_1574659096508 {
  margin-top: -50px !important;
}
.vc_custom_1574659148453 {
  margin-top: 30px !important;
}
.vc_custom_1571813426185 {
  margin-top: -20px !important;
}
.vc_custom_1584032837881 {
  margin-top: -20px !important;
}
.vc_custom_1582700648734 {
  margin-top: -20px !important;
}
.vc_custom_1573473483569 {
  margin-top: -20px !important;
  // margin-left: 10px !important;
}
.vc_custom_1584032628662 {
  margin-top: -20px !important;
  margin-left: 10px !important;
}
.vc_custom_1574663921406 {
  margin-left: 90px !important;
}
.vc_custom_1584904179088 {
  margin-bottom: -20px !important;
}
.vc_custom_1574068421003 {
  margin-left: 50px !important;
}
.vc_custom_1576135399621 {
  margin-left: 70px !important;
}
.vc_custom_1574664515008 {
  margin-left: 25px !important;
}
.vc_custom_1572417259402 {
  margin-top: -20px !important;
}
.vc_custom_1574163467970 {
  padding-top: -100px !important;
}
.vc_custom_1574163467970 {
  padding-top: -100px !important;
}

// Preloader

// Owly theme styles

.dt-sc-menu-sorting a {
  color: rgba(252, 210, 28, 0.6);
}
.dt-sc-team.type2 .dt-sc-team-thumb .dt-sc-team-thumb-overlay,
.dt-sc-hexagon-image span:before,
.dt-sc-keynote-speakers .dt-sc-speakers-thumb .dt-sc-speakers-thumb-overlay {
  background: rgba(252, 210, 28, 0.9);
}
.portfolio .image-overlay,
.recent-portfolio-widget ul li a:before,
.dt-sc-fitness-program-short-details-wrapper
  .dt-sc-fitness-program-short-details {
  background: rgba(252, 210, 28, 0.9);
}
.dt-sc-boxed-style.dt-sc-post-entry .blog-entry.sticky,
.dt-sc-post-entry.entry-cover-layout .blog-entry.sticky {
  box-shadow: inset 0 0 1px 5px #fcd21c;
}
.apply-no-space .dt-sc-boxed-style.dt-sc-post-entry .blog-entry.sticky,
.apply-no-space .dt-sc-post-entry.entry-cover-layout .blog-entry.sticky {
  box-shadow: inset 0 0 1px 3px #fcd21c;
}
.dt-sc-simple-style.dt-sc-post-entry.entry-grid-layout .blog-entry.sticky,
.dt-related-carousel div[class*="carousel-"] > div {
  box-shadow: 0 0 1px 1px #fcd21c;
}
.dt-sc-content-overlay-style.dt-sc-post-entry.entry-grid-layout
  .blog-entry.sticky
  .entry-thumb {
  box-shadow: 0 -3px 0 0 #fcd21c;
}
.dt-sc-modern-style.dt-sc-post-entry .blog-entry:hover {
  box-shadow: 0 5px 0 0 #fcd21c;
}
.dt-sc-grungy-boxed-style.dt-sc-post-entry .blog-entry:before,
.dt-sc-title-overlap-style.dt-sc-post-entry .blog-entry:before {
  box-shadow: inset 0 0 0 1px #fcd21c;
}
.dt-sc-icon-box.type10 .icon-wrapper:before,
.dt-sc-pr-tb-col.type2 .dt-sc-tb-header:before {
  box-shadow: 5px 0px 0px 0px #fcd21c;
}
.dt-sc-icon-box.type10:hover .icon-wrapper:before {
  box-shadow: 7px 0px 0px 0px #fcd21c;
}
.dt-sc-counter.type6 .dt-sc-couter-icon-holder:before {
  box-shadow: 5px 1px 0px 0px #fcd21c;
}
.dt-sc-button.with-shadow.white,
.dt-sc-pr-tb-col.type2 .dt-sc-buy-now a {
  box-shadow: 3px 3px 0px 0px #fcd21c;
}
.dt-sc-restaurant-events-list .dt-sc-restaurant-event-details h6:before {
  border-bottom-color: rgba(252, 210, 28, 0.6);
}
.portfolio.type4 .image-overlay,
.dt-sc-timeline-section.type4 .dt-sc-timeline-thumb-overlay,
.dt-sc-yoga-classes .dt-sc-yoga-classes-image-wrapper:before,
.dt-sc-yoga-course .dt-sc-yoga-course-thumb-overlay,
.dt-sc-yoga-program .dt-sc-yoga-program-thumb-overlay,
.dt-sc-yoga-pose .dt-sc-yoga-pose-thumb:before,
.dt-sc-yoga-teacher .dt-sc-yoga-teacher-thumb:before,
.dt-sc-doctors .dt-sc-doctors-thumb-overlay,
.dt-sc-event-addon > .dt-sc-event-addon-date,
.dt-sc-course .dt-sc-course-overlay,
.dt-sc-process-steps .dt-sc-process-thumb-overlay {
  background: rgba(252, 210, 28, 0.85);
}
.dt-sc-pr-tb-col.type3 .dt-sc-tb-header,
.dt-sc-pr-tb-col.type3:hover .dt-sc-pr-tb-col-wrapper,
.dt-sc-pr-tb-col.type3.selected .dt-sc-pr-tb-col-wrapper,
.dt-sc-pr-tb-col ul.dt-sc-pricing-table-type4-item li.first,
.dt-sc-pr-tb-col:hover ul.dt-sc-pricing-table-type4-item,
.dt-sc-pr-tb-col ul.dt-sc-pricing-table-type4-item.selected {
  background: rgba(252, 210, 28, 0.2);
}
@media only screen and (max-width: 767px) {
  .dt-sc-contact-info.type4:after,
  .dt-sc-icon-box.type10 .icon-content h6:after,
  .dt-sc-counter.type6.last h4::before,
  .dt-sc-counter.type6 h4::after {
    background-color: #fcd21c;
  }
}
@media only screen and (max-width: 767px) {
  .dt-sc-timeline-section.type2,
  .dt-sc-timeline-section.type2::before {
    border-color: #fcd21c;
  }
}
.dt-sc-icon-box.type2.custom-style:hover .icon-content h5,
.dt-sc-counter.type1.custom-style:hover .icon-wrapper {
  box-shadow: inset 0 0 0 8px #fcd21c;
}
.dt-sc-event-month-thumb .dt-sc-event-read-more,
.dt-sc-training-thumb-overlay {
  background: rgba(9, 9, 9, 0.85);
}
.dt-sc-team.hide-social-role-show-on-hover {
  background: rgba(9, 9, 9, 0.05);
}
@media only screen and (max-width: 767px) {
  .dt-sc-highlight .dt-sc-testimonial.type6 .dt-sc-testimonial-author:after,
  .dt-sc-highlight .dt-sc-testimonial.type6 .dt-sc-testimonial-author:after,
  .skin-highlight .dt-sc-testimonial.type6 .dt-sc-testimonial-author:after {
    background-color: #090909;
  }
}
.dt-sc-contact-info.type4 span:after {
  -webkit-box-shadow: 5px 5px 0px 0px#090909;
  -moz-box-shadow: 5px 5px 0px 0px#090909;
  -ms-box-shadow: 5px 5px 0px 0px#090909;
  -o-box-shadow: 5px 5px 0px 0px#090909;
  box-shadow: 5px 5px 0px 0px#090909;
}
.dt-sc-faculty .dt-sc-faculty-thumb-overlay {
  background: rgba(234, 179, 23, 0.9);
}
@-webkit-keyframes color-change {
  0% {
    color: #fcd21c;
  }
  50% {
    color: #090909;
  }
  100% {
    color: #eab317;
  }
}
@-moz-keyframes color-change {
  0% {
    color: #fcd21c;
  }
  50% {
    color: #090909;
  }
  100% {
    color: #eab317;
  }
}
@-ms-keyframes color-change {
  0% {
    color: #fcd21c;
  }
  50% {
    color: #090909;
  }
  100% {
    color: #eab317;
  }
}
@-o-keyframes color-change {
  0% {
    color: #fcd21c;
  }
  50% {
    color: #090909;
  }
  100% {
    color: #eab317;
  }
}
@keyframes color-change {
  0% {
    color: #fcd21c;
  }
  50% {
    color: #090909;
  }
  100% {
    color: #eab317;
  }
}

// portfolio swiper
.dtportfolio-item .dtportfolio-image-overlay .links a:hover,
.dtportfolio-item .dtportfolio-image-overlay a:hover,
.dtportfolio-fullpage-carousel .dtportfolio-fullpage-carousel-content a:hover,
.dtportfolio-item.dtportfolio-hover-modern-title
  .dtportfolio-image-overlay
  .links
  a:hover,
.dtportfolio-swiper-pagination-holder .dtportfolio-swiper-playpause:hover,
.dtportfolio-item.dtportfolio-hover-grayscale
  .dtportfolio-image-overlay-details
  h2
  a:hover {
  color: red;
}
.dtportfolio-swiper-pagination-holder .swiper-pagination-bullet-active {
  background: red;
}

//lazy load

.no-js img.lazyload {
  display: none;
}
figure.wp-block-image img.lazyloading {
  min-width: 150px;
}
.lazyload,
.lazyloading {
  opacity: 0;
}
.lazyloaded {
  opacity: 1;
  transition: opacity 400ms;
  transition-delay: 0ms;
}

// wp custom styles

.dt-sc-counter.type1.custom-style .icon-wrapper::before {
  height: 180px;
  width: 180px;
}
.dt-sc-counter.type1.custom-style .icon-wrapper {
  height: 155px;
  line-height: 155px;
  width: 155px;
}
.dt-sc-column-overflow-top > .wpb_column {
  margin-top: -95px;
}
textarea {
  height: 100px;
}
input[type="submit"],
button,
input[type="button"],
input[type="reset"] {
  padding: 2px 2px 2px;
}
.wpb_content_element {
  margin-bottom: 2px;
}
.dt-sc-special-testimonial.custom-style
  .dt-sc-special-testimonial-images-holder
  .dt-sc-testimonial-author
  cite
  small {
  line-height: 15px;
}
@media only screen and (min-width: 1100px) {
  .dt-sc-awards-heading:before,
  .dt-sc-awards-heading:after {
    background-image: url(/wp-content/themes/owly/images/awards-decor.png) !important;
    content: "";
    display: block;
    height: 153px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 101px;
  }
  .dt-sc-awards-heading:before,
  .dt-sc-awards-heading:after {
    background-image: url(/wp-content/themes/owly/images/awards-decor.png) !important;
    content: "";
    display: block;
    height: 153px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 101px;
  }
  .dt-sc-awards-heading:after {
    left: auto !important;
    right: 0 !important;
    transform: scaleX(-1) translateY(-50%) !important;
  }
  .quotes {
    width: 100% !important;
    text-align: center !important;
    margin-top: 100px !important;
    margin-bottom: 50px !important;
  }
  div[id="1571829203851-5f6accbe-3d82"] {
    height: 0px !important;
  }
  #fixed-header-sticky-wrapper {
    height: 96px !important;
  }
  #header .vc_custom_1580457070965 {
    // margin-top: 10px!important;
    // margin-top: 18px!important;
  }
  .wpb_gallery_slides .wpb_image_grid_ul {
    height: 170px !important;
  }
  #footable_parent_21802 #footable_21802:not(.hide_all_borders) tbody tr td {
    border-color: transparent !important;
  }
  #footable_parent_20720 #footable_20720:not(.hide_all_borders) tbody tr td {
    border-color: transparent !important;
  }
  #footable_21802,
  #footable_20720 {
    border-bottom: none !important;
  }
  h1 span {
    font-size: 32px !important;
  }
  h2 {
    font-size: 28px !important;
  }
  h3 {
    font-size: 24px !important;
  }
  h4 {
    font-size: 20px !important;
  }
  // h5 {
  // font-size: 18px!important;
  // }
  p {
    font-weight: 400 !important;
    font-size: 16px !important;
  }
  .circletick li {
    font-weight: normal;
    // font-weight: 400!important;
    // font-size: 16px!important;
  }
  .dt-sc-owly-footer {
    padding: 40px 0 0 !important;
  }
}
@media only screen and (max-width: 700px) {
  #footer h5 {
    font-size: 12px !important;
    font-weight: 500 !important;
  }
  input,
  textarea {
    font-size: 14px !important;
    padding: 10px !important;
    border: 1px dashed rgba(0, 0, 0, 0.15) !important;
  }
  #footer h3 {
    font-size: 22px !important;
    font-weight: 600 !important;
  }
  #footer p {
    font-size: 14px !important;
  }
  #footer li {
    // font-size: 14px!important;
    // list-style-type: none!important;
  }
  .dt-sc-single-line-dashed-separator {
    border-top: 1px dashed #090909;
  }
  #footer ul {
    // display: flex;
    // justify-content: space-evenly;
  }
  #footer .dt-sc-contact-info {
    padding-left: 20px;
    display: flex;
  }
  #footer .dt-sc-contact-info p {
    margin-right: 3px;
  }
  #footer .dt-sc-contact-info span {
    font-size: 15px !important;
    margin: 2px 0 0 0 !important;
  }
  .dt-sc-sociable.large li a {
    font-size: 14px !important;
    height: 38px !important;
    line-height: 38px !important;
    width: 38px !important;
  }
  #footer .vc_single_image-wrapper img {
    // width: 75px!important;
  }
  label {
    font-weight: 500 !important;
    font-size: 16px !important;
  }
  .dt-sc-title.with-right-border-decor {
    max-width: 100% !important;
  }
  .dt-sc-title.with-right-border-decor h2:first-child {
    font-size: 21px !important;
  }
  #header-56 .wpb_wrapper p {
    font-size: 13px !important;
  }
  .mobile-nav-container .menu-trigger > i:before {
    font-family: inherit !important;
  }
  header #header-56 #dt-1575631758496-d9036aaf-a97b {
    display: none !important;
  }
  div[id="1580458027323-a3283f03-6064"] {
    display: none !important;
  }
  #fixed-header .dt-sc-owly-header {
    display: flex !important;
    align-items: center;
  }
  #fixed-header .vc_custom_1580458008506 {
    padding-left: 0px !important;
  }
  #header-56 .vc_custom_1575631676548 {
    padding-left: 10px !important;
    padding-right: 10px !important;
    padding-top: 3px !important;
  }
  #dt-1580457390742-09000a6b-590e-mobile {
    text-align: right !important;
    padding-right: 20px !important;
  }
  .dt-sc-icon-box.type2.custom-style .icon-content {
    padding: 0px !important;
  }
  .dt-sc-icon-box.type2.custom-style .icon-content h6 {
    font-size: 16px !important;
  }
  .dt-sc-icon-box.type2 .icon-content p {
    font-size: 14px !important;
    line-height: 22px !important;
    font-weight: 500 !important;
  }
  .dt-sc-icon-box.type2.custom-style .icon-content h5 {
    color: $h-color !important;
    font-size: 40px !important;
    height: 120px !important;
    line-height: 120px !important;
    width: 120px !important;
    box-shadow: inset 0 0 0 2px #090909 !important;
    animation: none !important;
  }
  .dt-sc-icon-box.type2.custom-style .icon-content h5:before {
    border: 1px dashed #a7a7a7 !important;
    height: 130px !important;
    width: 130px !important;
  }
  div[class*="dt-sc-curvy"]:before {
    background-color: transparent !important;
  }
  div[id="1574503028787-571c597e-95a1"] {
    height: 0px !important;
  }
  div[id="1574503187261-2c55e320-e998"] {
    height: 0px !important;
  }
  blockquote.type2 q {
    border-bottom: 1px solid #090909;
    border-top: 1px solid #090909;
    font-size: 20px;
    line-height: 28px;
    padding: 30px 0;
  }
  .mobile-menu .dt-primary-nav > li > a {
    line-height: 52px !important;
    min-height: 52px !important;
    font-size: 15px !important;
  }
  .dt-sc-circular-icon-set {
    border-radius: 2% !important;
  }
  div[id="1574417185528-4853f7fe-c4e3"] {
    height: 0px !important;
  }
  div[id="1574417185205-d77d4e42-d454"] {
    height: 0px !important;
  }
  .dt-sc-special-testimonial.custom-style
    .dt-sc-special-testimonial-details-holder
    .dt-sc-testimonial-description {
    padding: 0px !important;
  }
  .dt-sc-special-testimonial.custom-style
    .dt-sc-special-testimonial-details-holder
    .dt-sc-testimonial-description {
    font-size: 14px !important;
  }
  #post-94 .vc_row-has-fill .vc_empty_space {
    height: 0px !important;
  }
  div[id="1574659062861-d226a010-5918"] {
    height: 15px !important;
  }
  .dt-sc-icon-box.type2.custom-style {
    margin-bottom: 15px !important;
  }
  div[id="1574659060846-a75d47c4-ff04"] {
    height: 0px !important;
  }
  .neetsguide {
    font-size: 27px !important;
    margin-bottom: 50px !important;
  }
  .vc_custom_1576476357032 {
    margin-top: 0px !important;
    margin-left: 0px !important;
  }
  .class1 {
    font-size: 14px;
  }
  .custom-style-2 .icon-content p {
    font-size: 18px !important;
  }
  .vc_custom_1584032628662 {
    margin-left: 0px !important;
  }
  .dt-sc-circular-icon-set {
    margin: 0px !important;
  }
  .vc_empty_space {
    height: 0px !important;
  }
  .dt-sc-owly-footer {
    padding: 0px !important;
  }
  div[id="1574417184980-6574c346-22ec"] {
    height: 0px !important;
  }
  .class2 {
    font-size: 24px !important;
  }
  div[id="1574418778314-2737e0af-8295"] {
    height: 20px !important;
  }
  .vc_custom_1574419049926 {
    margin-top: -25px !important;
  }
  .class3 {
    // font-size: 14px!important;
    font-weight: 400 !important;
  }
  .dt-sc-team-details h4 {
    font-size: 16px !important;
  }
  .dt-sc-team-details h5 {
    font-size: 12px !important;
    font-weight: 600;
    margin-bottom: 0px !important;
    margin-top: -12px !important;
  }
  .corevalues {
    padding-top: 60px !important;
  }
  .dt-sc-title.with-right-border-decor h3:first-child {
    font-size: 18px !important;
  }
  .vc_custom_1574488618266 .vc_custom_1573900280699 {
    display: block !important;
    margin-bottom: 10px !important;
  }
  .dt-sc-team .dt-sc-team-details {
    padding: 5px 0px;
    background-color: transparent !important;
  }
  .vc_col-sm-3 .type7 h3 {
    font-size: 18px !important;
  }
  .vc_col-sm-3 .type7 p {
    font-size: 15px !important;
    line-height: 20px !important;
    font-weight: 500;
  }
  #post-19781 .vc_custom_1573895918203 {
    margin-top: -70px !important;
  }
  .mobile-menu .dt-primary-nav > li > ul > li > a {
    line-height: 52px !important;
    min-height: 52px !important;
    font-size: 15px !important;
  }
  .neetpricing span {
    font-size: 25px !important;
    color: #000 !important;
    text-transform: uppercase;
    font-weight: 700;
  }
  .vc_custom_1576046682354,
  .vc_custom_1576051746387 {
    border: 1px solid #342978 !important;
  }
  .vc_custom_1576046682354 h1,
  .vc_custom_1576051746387 h1 {
    margin-bottom: 0px !important;
  }
  .vc_custom_1576046682354 h1 span,
  .vc_custom_1576051746387 h1 span {
    font-size: 24px !important;
    margin-top: 30px;
    font-weight: bold;
  }
  .vc_custom_1576046682354 h2 b,
  .vc_custom_1576051746387 h2 b {
    font-size: 22px !important;
    text-align: center !important;
  }
  .vc_custom_1576046682354 h3,
  .vc_custom_1576051746387 h3 {
    font-size: 20px !important;
    text-align: center !important;
  }
  .vc_custom_1576046682354 span,
  .vc_custom_1576051746387 span {
    font-size: 14px !important;
    text-align: center !important;
    font-weight: 400 !important;
  }
  .ninja_column_0 {
    display: none !important;
  }
  .ninja_column_1 {
    text-align: center !important;
  }
  .vc_custom_1576046720595,
  .vc_custom_1576056245384 {
    border-top-width: 0px !important;
    border-top-color: #342978 !important;
    border-radius: 0px !important;
  }
  .dt-sc-hr-invisible-large {
    margin: 0px !important;
  }
  div[id="1576479823177-2c7d0141-5b4a"] {
    height: 50px !important;
  }
  .vc_custom_1575546313357 {
    margin-top: 20px !important;
  }
  .circletick li {
    font-weight: normal;
    line-height: 30px !important;
    // font-size: 14px!important;
    // font-weight: 400!important;
  }
  div[id="1575702055864-58e0948c-0ca6"] {
    height: 40px !important;
  }
  .ult-carousel-wrapper {
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
  }
  div[id="1575701886610-7ee5ec59-ee26"] {
    height: 0px !important;
  }
  .dt-sc-tabs-horizontal {
    display: flex !important;
    overflow: scroll !important;
  }
  ul.dt-sc-tabs-horizontal li {
    width: 100% !important;
    min-width: max-content !important;
  }
  .vc_pageable-slide-wrapper .vc_col-sm-3 {
    width: 25% !important;
  }
  .vc_pageable-slide-wrapper {
    display: flex !important;
    flex-wrap: wrap !important;
  }
  #dt-1580457312532-5eeae2f9-281a img {
    width: 100px !important;
  }
  div[id="1573205639074-2af146f3-3536"] {
    height: 0px !important;
  }
  div[id="1573205602205-9891de13-1009"] {
    height: 30px !important;
  }
  div[id="1573196908899-4f5b00e1-cca5"] {
    height: 50px !important;
  }
  .post-20913 {
    margin-top: 30px !important;
  }
  .post-20925 {
    margin-top: 35px !important;
  }
  .vc_custom_1573903725917 {
    margin-bottom: -30px !important;
  }
  div[id="1573897773153-46a8ccae-1ec0"] {
    height: 0px !important;
  }
  .dt-sc-contact-info h3 {
    font-size: 20px !important;
    margin-bottom: 10px !important;
  }
  .dt-sc-contact-info p {
    font-size: 15px !important;
    line-height: 20px !important;
  }
  .dt-sc-contact-info.type2 {
    margin: 0 0 0px !important;
  }
  .fa-phone,
  .fa-map-marker-alt {
    // display: none!important;
  }
  #footer .dt-sc-contact-info p {
    text-align: center !important;
  }
  .class4 .icon-content h6:nth-child(2) {
    display: none !important;
  }
  #footable_21030 .ninja_column_0 {
    display: table-cell !important;
  }
  #footable_21030 td span {
    font-size: 12px !important;
  }
  .showsections .vc_hidden-xs {
    display: block !important;
  }
  .showsections .wpb_column .wpb_wrapper .wpb_wrapper .row .column {
    display: none !important;
  }
  div[id="1574661121884-992fd229-4e6f"] {
    height: 35px !important;
  }
  div[id="1574661122700-def93315-b1d4"] {
    height: 0px !important;
  }
  .class5 {
    font-size: 20px !important;
  }
  .dt-sc-counter.type1.custom-style .icon-wrapper {
    font-size: 40px !important;
    height: 120px !important;
    line-height: 120px !important;
    width: 120px !important;
    box-shadow: inset 0 0 0 2px #090909 !important;
    animation: none !important;
    margin-bottom: 0px !important;
  }
  .dt-sc-counter.type1.custom-style .icon-wrapper::before {
    border: 1px dashed #a7a7a7 !important;
    height: 130px !important;
    width: 130px !important;
  }
  .dt-sc-column-overflow-top > .wpb_column:not(:last-child) {
    margin-bottom: 110px !important;
  }
  .showsections .vc_hidden-xs .dt-sc-counter-number {
    font-size: 24px !important;
  }
  .showsections .vc_hidden-xs .type1 h4 {
    font-size: 15px !important;
  }
  #footable_parent_20720 table {
    margin-bottom: -2px !important;
    border-bottom: none !important;
  }
  #footable_parent_21802 table {
    margin-bottom: -2px !important;
    border-bottom: none !important;
  }
  #footable_parent_21802 #footable_21802:not(.hide_all_borders) tbody tr td {
    border-color: transparent !important;
  }
  #footable_parent_20720 #footable_20720:not(.hide_all_borders) tbody tr td {
    border-color: transparent !important;
  }
  .vc_custom_1578477683933 {
    border-top-width: 1px !important;
  }
  .dt-sc-awards-heading:before,
  .dt-sc-awards-heading:after {
    background-size: 45% !important;
    height: 80px !important;
    width: 95px !important;
  }
  div[id="1571809498698-8c263360-9370"] {
    height: 0px !important;
  }
  div[id="1571829203851-5f6accbe-3d82"] {
    height: 15px !important;
  }
  #post-94 .vc_custom_1582701231908 .slick-slider .slick-track div h3 a {
    font-size: 14px !important;
    line-height: 20px !important;
  }
  #post-94 .vc_custom_1582701231908 .slick-slider .slick-track div h3 {
    font-size: 14px !important;
    line-height: 20px !important;
  }
  #post-94 .vc_custom_1582701231908 .slick-slider .slick-track {
    transform: none !important;
    width: auto !important;
  }
  #post-94 .vc_custom_1582701231908 .slick-slider .slick-track div:nth-child(3),
  #post-94
    .vc_custom_1582701231908
    .slick-slider
    .slick-track
    div:nth-child(4) {
    display: none !important;
  }
  #post-94
    .vc_custom_1582701231908
    .slick-slider
    .slick-track
    div:nth-child(2) {
    margin-top: 10px !important;
    margin-bottom: 20px !important;
  }
  #neet {
    margin-top: 20px !important;
  }
  div[id="1574664820352-805b2b1c-d67b"] {
    height: 0px !important;
  }
  .showsections p {
    font-size: 14px !important;
    font-weight: 400 !important;
    margin-bottom: 30px !important;
    margin-top: 30px !important;
  }
  .gallerysection .wpb_image_grid_ul {
    display: flex !important;
    height: 65px !important;
  }
  .gallerysection .wpb_image_grid_ul li {
    position: initial !important;
  }
  .vc_custom_1574068421003,
  .vc_custom_1576135399621,
  .vc_custom_1574664515008 {
    margin-left: 0px !important;
  }
  .gallerysection .vc_custom_1576135399621 .wpb_image_grid_ul {
    height: 54px !important;
  }
  .dt-sc-team-thumb img {
    width: 90% !important;
    margin: auto !important;
  }
  .whychooseus .dt-sc-icon-box.type5 .icon-wrapper {
    display: none !important;
  }
  .whychooseus .dt-sc-icon-box.type5 {
    padding: 5px 0px 0px 0px !important;
    margin-bottom: 0px !important;
  }
  .whychooseus h3 {
    font-size: 20px !important;
    margin-bottom: 5px !important;
  }
  .whychooseus p {
    font-size: 14px !important;
    font-weight: 400 !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
    line-height: 20px !important;
  }
  .whychooseus h2 {
    margin-bottom: 40px !important;
    margin-top: 30px !important;
  }
  #header-56 .vc_custom_1575631676548 .vc_column-inner .wpb_wrapper {
    display: flex;
    align-items: end;
    justify-content: center;
    margin-right: 15px;
  }
  #header-56
    .vc_custom_1575631676548
    .vc_column-inner
    .wpb_wrapper
    .wpb_text_column:nth-child(1)
    p
    span {
    font-size: 0px !important;
  }
  #header-56
    .vc_custom_1575631676548
    .vc_column-inner
    .wpb_wrapper
    .wpb_text_column:nth-child(1)
    p
    span
    span {
    font-size: 13px !important;
    margin-top: 3px;
    text-align: left !important;
  }
  .fa-envelope {
    display: none !important;
  }
  .quotes p {
    line-height: 22px !important;
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
}
.grecaptcha-badge {
  opacity: 0;
}

// Kirki inline styles

.breadcrumb a:hover,
.breadcrumb .fa.default:before {
  color: #fcd21c;
}
.menu-icons-wrapper .overlay-search #searchform:before,
#header .header-25 .dt-sc-sociable:before,
#header .header-25 .menu-icons-wrapper .search a,
#header .header-25 .cart-info:before,
#header .header-25 .dt-header-menu .dt-primary-nav > li:before {
  color: #fcd21c;
}
.widget #wp-calendar td a:hover,
.dt-sc-dark-bg .widget #wp-calendar td a:hover,
.secondary-sidebar .widget ul li > a:hover,
.dt-sc-practices-list li:before,
.secondary-sidebar .type15 .widget.widget_recent_reviews ul li .reviewer,
.secondary-sidebar
  .type15
  .widget.widget_top_rated_products
  ul
  li
  .amount.amount,
#main-menu .menu-item-widget-area-container .widget ul li > a:hover,
#main-menu
  .dt-sc-dark-bg
  .menu-item-widget-area-container
  .widget
  ul
  li
  > a:hover,
#main-menu
  .dt-sc-dark-bg
  .menu-item-widget-area-container
  .widget_recent_posts
  .entry-title
  h4
  a:hover,
#main-menu ul li.menu-item-simple-parent.dt-sc-dark-bg ul li a:hover,
#main-menu .menu-item-widget-area-container .widget li:hover:before {
  color: #fcd21c;
}
.dt-sc-skin-color,
.dt-sc-skin-color a,
#footer .wpcf7-form.bottom-bordered input[type="submit"],
#footer .wpcf7-form.bottom-bordered button,
#footer .wpcf7-form.bottom-bordered input[type="button"],
#footer .wpcf7-form.bottom-bordered input[type="reset"],
#footer h1 strong,
#footer h2 strong,
#footer h3 strong,
#footer h4 strong,
#footer h5 strong,
#footer h6 strong,
#footer
  .dt-sc-dark-bg.map-with-overlay
  .map-overlay.wpb_column
  .dt-sc-tabs-horizontal-container
  ul.dt-sc-tabs-horizontal
  > li
  > a:hover,
#footer
  .dt-sc-dark-bg.map-with-overlay
  .map-overlay.wpb_column
  .dt-sc-tabs-horizontal-container
  ul.dt-sc-tabs-horizontal
  > li
  > a.current,
#footer
  .dt-sc-light-bg.map-with-overlay
  .map-overlay.wpb_column
  .dt-sc-tabs-horizontal-container
  ul.dt-sc-tabs-horizontal
  > li
  > a:hover,
#footer
  .dt-sc-light-bg.map-with-overlay
  .map-overlay.wpb_column
  .dt-sc-tabs-horizontal-container
  ul.dt-sc-tabs-horizontal
  > li
  > a.current,
.footer-20 .widget .recent-posts-widget li .entry-meta p,
#footer .footer-21 .dt-sc-sociable.partially-rounded li > a:hover > i,
#footer
  .footer-22
  .dt-sc-newsletter-section.type6
  .dt-sc-subscribe-frm
  input[type="submit"],
#footer
  .footer-22
  .dt-sc-newsletter-section.type6
  .dt-sc-subscribe-frm
  input[type="email"],
#footer
  .footer-22
  .dt-sc-newsletter-section.type6
  .dt-sc-subscribe-frm
  input[type="text"],
#footer .footer-24 .widget.widget_recent_entries ul li .post-date,
#footer .footer-25.dt-sc-skin-highlight input[type="submit"],
#footer .footer-25.dt-sc-skin-highlight button,
#footer .footer-25.dt-sc-skin-highlight input[type="button"],
#footer .footer-25.dt-sc-skin-highlight input[type="reset"],
#footer .footer-29 .dt-sc-button.fully-rounded-border,
.footer-29 .dt-sc-contact-info.type1:hover span,
.footer-30 .dt-sc-contact-info.type1 span,
#footer .footer-30 .dt-mc-subscribe form .btn-wrap.icon-only i,
.footer-34 .wpb_column:hover h3:before,
#footer .footer-27 .dt-sc-contact-info.type1 a:hover,
#footer .footer-28.footer-copyright.dt-sc-dark-bg a:hover {
  color: #fcd21c;
}
.dt-sc-post-entry .blog-entry a,
.dt-sc-post-entry .blog-entry .entry-title h4 a:hover,
.dt-sc-post-entry.entry-cover-layout .blog-entry .entry-title h4 a:hover,
.dt-sc-post-entry.entry-cover-layout
  .blog-entry
  .entry-button
  a.dt-sc-button:hover,
.dt-sc-post-entry.entry-cover-layout .blog-entry:after,
.dt-sc-boxed-style.dt-sc-post-entry
  .blog-entry
  > div.entry-meta-group
  .div:not(.entry-social-share)
  i,
.dt-sc-post-entry.entry-cover-layout .blog-entry .entry-format a:after,
.dt-sc-content-overlay-style.dt-sc-post-entry
  .blog-entry.type-post
  .entry-format
  a:hover,
.dt-sc-content-overlay-style.dt-sc-post-entry .blog-entry div.entry-tags a,
.dt-sc-content-overlay-style.dt-sc-post-entry .blog-entry > div.entry-date i,
.dt-sc-post-entry.entry-cover-layout .blog-entry > div.entry-format a:hover,
.dt-sc-simple-withbg-style.dt-sc-post-entry
  .blog-entry
  .entry-social-share
  .share
  > i,
.dt-sc-simple-withbg-style.dt-sc-post-entry
  .blog-entry
  .entry-button
  a.dt-sc-button,
.dt-sc-simple-withbg-style.dt-sc-post-entry .blog-entry .entry-format a,
.dt-sc-trendy-style.dt-sc-post-entry.entry-cover-layout
  .blog-entry
  .entry-details
  a,
.dt-sc-trendy-style.dt-sc-post-entry.entry-cover-layout .blog-entry > div a,
.dt-sc-trendy-style.dt-sc-post-entry.entry-cover-layout
  .blog-entry
  > div.entry-button
  a:hover,
.dt-sc-mobilephone-style.dt-sc-post-entry.entry-cover-layout:hover
  .blog-entry
  .entry-title
  h4
  a:hover,
.dt-sc-mobilephone-style.dt-sc-post-entry.entry-cover-layout:hover
  .blog-entry:before,
.dt-sc-mobilephone-style.dt-sc-post-entry.entry-cover-layout
  .blog-entry.sticky:before,
.dt-sc-alternate-style.dt-sc-post-entry:hover
  .blog-entry
  .entry-format
  a:before,
.dt-sc-simple-withbg-style.dt-sc-post-entry
  .blog-entry
  .entry-title
  h4
  span.sticky-post,
.dt-sc-simple-withbg-style.dt-sc-post-entry
  .blog-entry
  .entry-title
  h4
  span.sticky-post
  i,
.dt-sc-classic-overlay-style.dt-sc-post-entry.entry-grid-layout
  .blog-entry
  > .entry-tags
  > a,
.dt-sc-classic-overlay-style.dt-sc-post-entry.entry-grid-layout
  .blog-entry.sticky
  .entry-thumb
  .entry-format
  a:before,
.dt-sc-classic-overlay-style.dt-sc-post-entry
  .blog-entry
  .entry-thumb:first-child
  + .entry-meta-group
  > div
  > a:hover,
.blog-single-entry.post-custom-minimal
  div[class*="metagroup-"]
  div[class*="entry-"]
  a:hover,
.dt-sc-grungy-boxed-style.dt-sc-post-entry
  .blog-entry.has-post-thumbnail
  > div.entry-thumb
  + div.entry-comments
  a:hover,
.dt-sc-grungy-boxed-style.dt-sc-post-entry
  .blog-entry.has-post-thumbnail
  > div.entry-thumb
  + div.entry-likes-views
  a:hover,
.dt-sc-grungy-boxed-style.dt-sc-post-entry
  .blog-entry:not(.has-post-thumbnail)
  > div.entry-comments:first-child
  a:hover,
.dt-sc-grungy-boxed-style.dt-sc-post-entry
  .blog-entry:not(.has-post-thumbnail)
  > div.entry-likes-views:first-child
  a:hover,
.commentlist li.comment .reply a {
  color: #fcd21c;
}
.portfolio .image-overlay .links a:hover,
.portfolio.type7 .image-overlay .links a,
.project-details li a:hover,
.portfolio-categories a:hover,
.dt-portfolio-single-slider-wrapper #bx-pager a.active:hover:before,
.dt-portfolio-single-slider-wrapper #bx-pager a,
.portfolio.type8 .image-overlay .links a {
  color: #fcd21c;
}
.dt-skin-primary-color,
ul.side-nav li a:hover,
.available-domains li span,
.dt-sc-text-with-icon span,
.dt-sc-contact-info.type3 span,
.dt-sc-events-list .dt-sc-event-title h5 a,
.side-navigation.type5 ul.side-nav li.current_page_item a,
.side-navigation.type5 ul.side-nav > li > a:hover,
.carousel-arrows a:hover:before,
.dt-sc-pr-tb-col.type5:hover .dt-sc-tb-header .dt-sc-tb-price h2 span,
.dt-sc-pr-tb-col.type5:hover .dt-sc-tb-title h5,
.dt-sc-pr-tb-col.type5.selected .dt-sc-tb-title h5,
.dt-sc-pr-tb-col.type5.selected .dt-sc-tb-header .dt-sc-tb-price h2 span,
.dt-sc-testimonial.type2 blockquote:after {
  color: #fcd21c;
}
.dt-sc-button.with-shadow.white,
.dt-sc-skin-highlight .dt-sc-button.rounded-border:hover,
.dt-sc-skin-highlight .dt-sc-button.bordered:hover,
.dt-sc-dark-bg.skin-color .dt-sc-button.fully-rounded-border:hover,
.dt-sc-button.animated:hover:before,
.dt-sc-button.animated:hover:after,
.dt-sc-post-entry .blog-entry .entry-button a.dt-sc-button:hover:before,
.dt-sc-post-entry .blog-entry .entry-button a.dt-sc-button:hover:after,
.error404 .type2 .dt-sc-button:before,
.error404 .type2 .dt-sc-button:after {
  color: #fcd21c;
}
.dt-sc-icon-box.type1 .icon-wrapper .icon,
.dt-sc-icon-box.type2 .icon-wrapper .icon,
.dt-sc-icon-box.type4 .icon-wrapper span,
.dt-sc-icon-box.type5:hover .icon-content h6 a,
.dt-sc-icon-box.type5.no-icon-bg .icon-wrapper span,
.dt-sc-icon-box.type5.no-icon-bg:hover .icon-wrapper span,
.dt-sc-icon-box.type10 .icon-wrapper span,
.dt-sc-icon-box.type10:hover .icon-content h6,
.dt-sc-icon-box.type14 .icon-content h6,
.dt-sc-icon-box.type12 .icon-wrapper span,
.dt-sc-icon-box.type12 .icon-content h6 strong,
.dt-sc-icon-box.type16 .icon-content h6 {
  color: #fcd21c;
}
.dt-sc-testimonial.type4 .dt-sc-testimonial-author cite,
.dt-sc-testimonial.type5 .dt-sc-testimonial-author cite,
.dt-sc-testimonial.type8 .dt-sc-testimonial-quote blockquote q:before,
.dt-sc-testimonial.type8 .dt-sc-testimonial-quote blockquote q:after,
.dt-sc-testimonial-special-wrapper:after,
.dt-sc-special-testimonial-images-holder
  .dt-sc-testimonial-image.slick-current
  .dt-sc-testimonial-author
  cite,
.dt-sc-team-carousel-wrapper .dt-sc-team-details .dt-sc-team-social li a:hover,
.dt-sc-special-testimonial-details-holder .dt-sc-testimonial-description:before,
.dt-sc-testimonial.type7:hover .dt-sc-testimonial-author:before,
.dt-sc-testimonial.type8:hover blockquote:before,
.dt-sc-testimonial.type7 .dt-sc-testimonial-author:before {
  color: #fcd21c;
}
ul.dt-sc-tabs-horizontal-frame > li > a.current,
ul.dt-sc-tabs-horizontal > li > a.current,
ul.dt-sc-tabs-horizontal > li > a:hover,
ul.dt-sc-tabs-horizontal-frame > li > a:hover,
.type7 ul.dt-sc-tabs-horizontal-frame > li > a.current {
  color: #fcd21c;
}
ul.dt-sc-tabs-vertical-frame > li > a:hover,
ul.dt-sc-tabs-vertical-frame > li.current a,
ul.dt-sc-tabs-vertical > li > a.current,
.dt-sc-tabs-vertical-frame-container.type2
  ul.dt-sc-tabs-vertical-frame
  > li
  > a.current:before,
ul.dt-sc-tabs-vertical > li > a:hover {
  color: #fcd21c;
}
.dt-sc-toggle-frame-set > .dt-sc-toggle-accordion.active > a,
.dt-sc-toggle-group-set .dt-sc-toggle.active > a,
.dt-sc-toggle-frame h5.dt-sc-toggle-accordion.active a,
.dt-sc-toggle-frame h5.dt-sc-toggle.active a,
.dt-sc-toggle-panel h2 span {
  color: #fcd21c;
}
.dt-sc-title.with-sub-title h3,
.dt-sc-title.with-two-color-stripe h2,
.dt-sc-hexagon-title h2 span,
#footer .footer-22 .dt-sc-title.script-with-sub-title h3,
.side-navigation-content .dt-sc-title.script-with-sub-title strong,
.dt-sc-title.with-two-color-bg h2,
.dt-sc-ribbon-title:after,
.dt-sc-title.script-with-sub-title h1 strong,
.dt-sc-title.script-with-sub-title h2 strong,
.dt-sc-title.script-with-sub-title h3 strong,
.dt-sc-title.script-with-sub-title h4 strong,
.dt-sc-title.script-with-sub-title h5 strong,
.dt-sc-title.script-with-sub-title h6 strong {
  color: #fcd21c;
}
.dt-sc-image-with-caption h3 a,
.dt-sc-event-image-caption .dt-sc-image-content h3,
.dt-sc-image-caption.type8:hover .dt-sc-image-content h3 a:hover,
.dt-sc-image-caption.type3 .dt-sc-image-wrapper .icon-wrapper span {
  color: #fcd21c;
}
.dt-sc-team.simple-rounded-image:hover
  .dt-sc-team-details
  .dt-sc-team-social
  li
  a:hover,
.dt-sc-team.rounded.team_rounded_border:hover .dt-sc-team-details h4,
.dt-sc-team.type2 .dt-sc-team-social.rounded-border li a:hover,
.dt-sc-team.type2 .dt-sc-team-social.rounded-square li a:hover,
.dt-sc-team.type2 .dt-sc-team-social.hexagon-border li a:hover,
.dt-sc-team.type2 .dt-sc-team-social.diamond-square-border li a:hover {
  color: #fcd21c;
}
.dt-sc-timeline .dt-sc-timeline-content h2 span,
.dt-sc-hr-timeline-section.type2 .dt-sc-hr-timeline-content:hover h3,
.dt-sc-timeline-section.type4 .dt-sc-timeline:hover .dt-sc-timeline-content h2,
.dt-sc-timeline-description h5,
.dt-sc-timeline-item.slick-current h4,
.dt-sc-timeline-section.dt-sc-vc-special
  .dt-sc-timeline-image-wrapper
  .dt-sc-timeline-thumb-overlay
  h5,
.dt-sc-timeline-section.dt-sc-vc-special
  .dt-sc-timeline
  .dt-sc-timeline-content
  span,
.dt-sc-timeline-section.dt-sc-vc-special
  .dt-sc-timeline:hover
  .dt-sc-timeline-content
  h2 {
  color: #fcd21c;
}
.dt-sc-sociable.diamond-square-border li:hover a,
.dt-sc-sociable.hexagon-border li:hover a,
.dt-sc-sociable.hexagon-with-border li:hover a,
.dt-sc-sociable.no-margin li a {
  color: #fcd21c;
}
.dt-sc-counter.type3.diamond-square h4,
.dt-sc-counter.type6:hover h4,
.dt-sc-counter.type1 .icon-wrapper span,
.dt-sc-counter.type2 .dt-sc-couter-icon-holder,
.dt-sc-counter.type2 .icon-wrapper span,
.dt-sc-counter.type2 span:before,
.dt-sc-counter.type3 .dt-sc-counter-number,
.dt-sc-counter.type6 .dt-sc-couter-icon-holder .icon-wrapper span,
.dt-sc-counter.type1.custom-style:hover .icon-wrapper span {
  color: #fcd21c;
}
.wpb_wrapper > h1 strong,
.wpb_wrapper > h2 strong,
.wpb_wrapper > h3 strong,
.wpb_wrapper > h4 strong,
.wpb_wrapper > h5 strong,
.wpb_wrapper > h6 strong,
.dt-sc-icon-box.type1.custom-style:before,
.dt-sc-icon-box.type1.custom-style:hover p a,
.dt-sc-icon-box.type1.custom-style:hover .icon-content + *,
div[class*="custom-style"].dt-sc-icon-box.type3:hover .icon-wrapper span,
div[class*="custom-style"].dt-sc-icon-box.type13:before,
div[class*="custom-style"].dt-sc-icon-box.type13:hover:before,
.vc_row.dt-sc-fullwidth-iconboxes
  > .wpb_column
  .dt-sc-icon-box.type2.custom-style:hover
  .icon-content
  h5,
.vc-hoverbox-wrapper.custom-style.type-1
  .vc-hoverbox-block.vc-hoverbox-back:before,
.dt-sc-team-shortcode-description .dt-sc-team-social li a:hover {
  color: #fcd21c;
}
th,
.loader,
input[type="submit"]:hover,
input[type="reset"]:hover,
button:hover,
input[type="button"]:hover {
  background-color: #fcd21c;
}
.overlay .overlay-close,
#header .header-25 .dt-sc-sociable li a,
#header .header-27 .cart-info:before,
#header .header-28 .cart-icon span,
.dt-header-menu ul.dt-primary-nav li ul.sub-menu li a:hover,
.dt-header-menu ul.dt-primary-nav li ul.sub-menu li.current-menu-item a,
.dt-header-menu ul.dt-primary-nav li ul.sub-menu li.current_page_item a,
.dt-header-menu ul.dt-primary-nav li ul.sub-menu > li:hover > a {
  background-color: #fcd21c;
}
#footer .wpcf7-form.bottom-bordered input[type="submit"]:hover,
#footer .wpcf7-form.bottom-bordered button:hover,
#footer .wpcf7-form.bottom-bordered input[type="button"]:hover,
#footer .wpcf7-form.bottom-bordered input[type="reset"]:hover,
.footer-20 .footer-20-contact .vc_column-inner,
#footer
  .footer-22
  .dt-sc-newsletter-section.type6
  .dt-sc-subscribe-frm
  input[type="submit"]:hover,
#footer .footer-24 .widget.widget_recent_entries ul li:before,
.footer-29 h3:before,
.footer-28 .dt-sc-contact-info.type8:hover span,
.dt-sc-footer-info .vc_column-inner .wpb_wrapper > h2 {
  background-color: #fcd21c;
}
.widget
  .dt-sc-newsletter-section.boxed
  .dt-sc-subscribe-frm
  input[type="submit"]:hover,
.tagcloud a:hover,
.widgettitle:before,
.widget.widget_categories ul li > a:hover span,
.widget.widget_archive ul li > a:hover span,
.dt-sc-dark-bg .tagcloud a:hover,
.dt-sc-dark-bg .widget.widget_categories ul li > a:hover span,
#footer .dt-sc-dark-bg .widget.widget_categories ul li > a:hover span,
#footer .dt-sc-dark-bg .widget.widget_archive ul li > a:hover span {
  background-color: #fcd21c;
}
.blog-entry .entry-title h4 span.sticky-post,
.blog-entry .entry-social-share .share > i,
.dt-sc-post-entry .blog-entry .entry-button a.dt-sc-button,
.dt-sc-post-entry.entry-cover-layout .blog-entry .entry-social-share .share > i,
.dt-sc-post-entry .blog-entry .entry-format a,
.dt-sc-simple-style.dt-sc-post-entry .blog-entry .entry-format a:hover,
.dt-sc-content-overlay-style.dt-sc-post-entry
  .blog-entry
  div.entry-categories
  a,
.dt-sc-content-overlay-style.dt-sc-post-entry
  .blog-entry
  > div.entry-tags
  a:hover,
.dt-sc-content-overlay-style.dt-sc-post-entry
  .blog-entry
  > div.entry-author
  > a:hover,
.dt-sc-content-overlay-style.dt-sc-post-entry
  .blog-entry
  div.entry-comments
  > a:hover,
.dt-sc-content-overlay-style.dt-sc-post-entry
  .blog-entry
  div.entry-tags
  a:hover,
.dt-sc-simple-withbg-style.dt-sc-post-entry .blog-entry,
.dt-sc-simple-withbg-style.dt-sc-post-entry .blog-entry .entry-format a:hover,
.dt-sc-simple-withbg-style.dt-sc-post-entry .blog-entry.sticky .entry-format a,
.dt-sc-simple-withbg-style.dt-sc-post-entry.entry-grid-layout
  .blog-entry
  .entry-thumb
  .bx-wrapper,
.dt-sc-mobilephone-style.dt-sc-post-entry.entry-cover-layout:hover
  .blog-entry
  div.entry-format
  a,
.dt-sc-mobilephone-style.dt-sc-post-entry.entry-cover-layout
  .blog-entry.sticky
  div.entry-format
  a,
.pagination .newer-posts a,
.pagination .older-posts a,
.pagination ul li span,
.pagination ul li a:hover,
.pagination a.loadmore-btn,
.dt-sc-alternate-style.dt-sc-post-entry:hover .entry-title h4 a:before,
.dt-sc-alternate-style.dt-sc-post-entry .blog-entry .entry-format a:after,
.dt-sc-content-overlay-style.dt-sc-post-entry
  .blog-entry
  div.entry-author
  a:hover,
.dt-sc-classic-overlay-style.dt-sc-post-entry
  .blog-entry
  > .entry-categories
  > a:hover,
.dt-sc-overlap-style.dt-sc-post-entry .blog-entry .entry-format a:after,
.dt-related-carousel div[class*="carousel-"] > div,
.dt-related-carousel .carousel-pager > a.selected,
.dt-related-carousel .carousel-pager > a:hover,
.dt-sc-overlay-iii-style.dt-sc-post-entry.entry-list-layout
  .blog-entry
  > .entry-thumb:before,
.dt-sc-modern-style.dt-sc-post-entry
  .blog-entry
  .entry-meta-group
  div.entry-tags
  a,
.dt-sc-overlay-style.dt-sc-post-entry.entry-cover-layout
  .blog-entry
  .entry-details
  > .entry-tags,
.dt-sc-minimal-style.dt-sc-post-entry.entry-grid-layout .blog-entry:after,
.dt-sc-title-overlap-style.dt-sc-post-entry
  .blog-entry.sticky
  > div.entry-title:before,
.dt-sc-title-overlap-style.dt-sc-post-entry
  .blog-entry:hover
  > div.entry-title:before,
.post-edit-link:hover,
.vc_inline-link:hover,
ul.commentlist li .reply a:hover,
.single-post-header-wrapper > .container .post-categories a {
  background-color: #fcd21c;
}
.dt-sc-portfolio-sorting a.active-sort,
.dt-sc-portfolio-sorting a:hover,
.dt-sc-portfolio-sorting a:hover:before,
.dt-sc-portfolio-sorting a:hover:after,
.dt-sc-portfolio-sorting a.active-sort:before,
.dt-sc-portfolio-sorting a.active-sort:after,
.portfolio.type2 .image-overlay-details,
.portfolio.type2 .image-overlay .links a:hover,
.dt-sc-portfolio-sorting.type2,
.dt-sc-portfolio-sorting.type2:before,
.portfolio.type6 .image-overlay .links a:hover,
.portfolio.type7 .image-overlay-details .categories a:before,
.portfolio.type7 .image-overlay .links a:hover:before {
  background-color: #fcd21c;
}
.dt-skin-primary-bg,
div[class*="dt-skin-primary-bg-opaque"]:not(.ult-vc-hide-row):before,
div[class*="dt-skin-primary-bg-opaque"] .upb_row_bg:before,
section[class*="dt-skin-primary-bg-opaque"]:before,
ul.side-nav li a:hover:before,
ul.side-nav > li.current_page_item > a:before,
ul.side-nav > li > ul > li.current_page_item > a:before,
ul.side-nav > li > ul > li > ul > li.current_page_item > a:before,
.dt-sc-small-separator,
.dt-sc-diamond-separator,
.dt-sc-titled-box h6.dt-sc-titled-box-title,
.dt-sc-images-wrapper .carousel-arrows a:hover,
.diamond-narrow-square-border li:hover:before,
.dt-sc-sociable.hexagon-with-border li,
.dt-sc-skin-highlight,
.dt-sc-skin-highlight.extend-bg-fullwidth-left:after,
.dt-sc-skin-highlight.extend-bg-fullwidth-right:after,
.dt-skin-primary-bg.extend-bg-fullwidth-left:after,
.dt-skin-primary-bg.extend-bg-fullwidth-right:after,
.two-color-section:before,
.dt-sc-readmore-plus-icon:hover:before,
.dt-sc-readmore-plus-icon:hover:after,
.dt-sc-contact-details-on-map .map-switch-icon,
.dt-sc-content-with-hexagon-shape,
.dt-sc-hexagons li .dt-sc-hexagon-overlay,
.available-domains li .tdl:before,
.available-domains li:hover .dt-sc-button,
.domain-search-container .domain-search-form,
.dt-sc-newsletter-section.type1 h2:before,
.dt-sc-newsletter-section.type1 h2:after,
.side-navigation.type2 ul.side-nav > li.current_page_item > a,
.side-navigation.type3 ul.side-nav > li.current_page_item > a,
.side-navigation.type3 ul.side-nav > li:hover > a,
.side-navigation.type4 ul.side-nav li a:after,
.side-navigation.type5 ul.side-nav li:after,
.dt-mc-subscribe.only-border-bottom form:before,
blockquote.type4,
.dt-sc-newsletter-section.type2 .dt-sc-subscribe-frm input[type="submit"],
#toTop:hover:after {
  background-color: #fcd21c;
}
.dt-sc-button.filled,
.dt-sc-button.rounded-border:hover,
.dt-sc-button.bordered:hover,
.dt-sc-button.fully-rounded-border:hover,
.dt-sc-colored-big-buttons:hover,
.dt-sc-colored-big-buttons span,
.dt-sc-button.with-icon.icon-right.type3:hover span,
.error404 .type2 .dt-sc-button:hover {
  background-color: #fcd21c;
}
.dt-sc-contact-info.type2 span,
.dt-sc-contact-info.type3,
.dt-sc-contact-info.type4 span:after,
.dt-sc-contact-info.type4:before,
.dt-sc-contact-info.type5 .dt-sc-contact-icon,
.dt-sc-contact-info.type5:hover,
.dt-sc-contact-info.type6,
.dt-sc-contact-info.type7 span:after,
.dt-sc-contact-info.type4:after,
.university-contact-form .button-field i,
.dt-sc-switcher-back:before,
.map-form-switcher .switcher-toggle,
.dt-sc-map-form-holder.map-active .map-form-switcher .switcher-bg,
.dt-sc-map-form-holder.map-active .map-form-switcher .switcher-toggle .icon,
.dt-sc-contact-info.type8 span,
.dt-sc-contact-info.type8:after,
.dt-sc-contact-info.type8 h6:after,
.dt-sc-contact-info.type7:hover span,
.dt-sc-contact-info.type9:hover span {
  background-color: #fcd21c;
}
.dt-sc-counter.type1 .icon-wrapper:before,
.dt-sc-counter.type3.diamond-square
  .dt-sc-couter-icon-holder
  .icon-wrapper:before,
.dt-sc-counter.type4:hover .dt-sc-couter-icon-holder,
.dt-sc-counter.type5:hover:after,
.dt-sc-counter.type6 h4:before,
.dt-sc-counter.type6:hover .dt-sc-couter-icon-holder:before,
.dt-sc-counter.type2:hover .icon-wrapper span.zmdi,
.dt-sc-counter.type2:hover .icon-wrapper span.pe-icon,
.dt-sc-counter.type2:hover .icon-wrapper span.icon,
.dt-sc-counter.type2:hover .icon-wrapper span.fa,
.dt-sc-counter.type2:hover .icon-wrapper span.fas,
.dt-sc-counter.type2:hover h4:after,
.dt-sc-counter.type2 h4:after,
.dt-sc-counter.type3 .dt-sc-counter-number:after,
.dt-sc-counter.type5 h4:after {
  background-color: #fcd21c;
}
.dt-sc-icon-box.type1 .icon-content h6:before,
.dt-sc-icon-box.type3 .icon-wrapper span,
.dt-sc-icon-box.type3.dt-sc-diamond:hover .icon-wrapper:after,
.dt-sc-icon-box.type5.rounded-skin .icon-wrapper,
.dt-sc-icon-box.type5.rounded:hover .icon-wrapper,
.dt-sc-icon-box.type5.alter .icon-wrapper:before,
.dt-sc-icon-box.type6 .icon-wrapper,
.dt-sc-icon-box.type7 .icon-wrapper,
.dt-sc-contact-info.type8:hover span,
.dt-sc-icon-box.type10:hover .icon-wrapper:before,
.dt-sc-icon-box.type10 .icon-content h6:before,
.dt-sc-icon-box.type11:before,
.dt-sc-icon-box.type14:hover,
.dt-sc-icon-box.type15 .icon-content,
.dt-sc-icon-box.type12 .icon-content h5:after,
.dt-sc-icon-box.type5 .icon-wrapper:before,
.dt-sc-icon-box.type10 .icon-content h6:after {
  background-color: #fcd21c;
}
.dt-sc-testimonial-wrapper .dt-sc-testimonial-bullets a:hover,
.dt-sc-testimonial-wrapper .dt-sc-testimonial-bullets a.active,
.dt-sc-testimonial.type8:hover {
  background-color: #fcd21c;
}
.dt-sc-triangle-title:after,
.dt-sc-title.with-right-border-decor:after,
.dt-sc-title.with-right-border-decor:before,
.dt-sc-title.with-boxed,
.mz-title .mz-title-content h2,
.mz-title-content h3.widgettitle,
.mz-title .mz-title-content:before,
.mz-blog .comments a,
.mz-blog div.vc_gitem-post-category-name,
.mz-blog .ico-format,
.side-navigation-content .dt-sc-wings-heading:after,
.animated-twin-lines:after,
.dt-sc-ribbon-title:before {
  background-color: #fcd21c;
}
.dt-sc-team-social.hexagon-border li:hover,
.dt-sc-team .dt-sc-team-social.diamond-square-border li:hover,
.dt-sc-team.hide-social-role-show-on-hover
  .dt-sc-team-social.rounded-square
  li:hover
  a,
.dt-sc-infinite-portfolio-load-more,
.dt-sc-single-hexagon .dt-sc-single-hexagon-overlay,
.dt-sc-team-social.rounded-border li a:hover,
.dt-sc-team-social.rounded-square li a,
.dt-sc-team.hide-social-show-on-hover:hover .dt-sc-team-details,
.dt-sc-team-social.square-border li a:hover,
.dt-sc-team.rounded:hover .dt-sc-team-thumb:after,
.dt-sc-team.hide-social-role-show-on-hover.default .dt-sc-team-social li,
.dt-sc-team.style2 .dt-sc-sociable li a,
.dt-sc-team.style2 .dt-sc-team-details .view-details:hover,
.dt-sc-team.type2:hover:after,
.dt-sc-team.hide-social-role-show-on-hover .dt-sc-team-details h5:after,
.dt-sc-team.hide-social-role-show-on-hover .dt-sc-team-thumb:before,
.dt-sc-team.hide-social-role-show-on-hover:hover .dt-sc-team-thumb,
.dt-sc-team.flip-details-on-hover .dt-sc-team-thumb .team-title {
  background-color: #fcd21c;
}
.dt-sc-pr-tb-col.minimal:hover .dt-sc-price,
.dt-sc-pr-tb-col.minimal.selected .dt-sc-price,
.dt-sc-pr-tb-col:hover .dt-sc-buy-now a,
.dt-sc-pr-tb-col.selected .dt-sc-buy-now a,
.dt-sc-pr-tb-col.minimal:hover .icon-wrapper:before,
.dt-sc-pr-tb-col.minimal.selected .icon-wrapper:before,
.dt-sc-pr-tb-col.type1:not(.classic):hover .dt-sc-tb-header,
.dt-sc-pr-tb-col.type1.selected .dt-sc-tb-header,
.dt-sc-pr-tb-col.type2 .dt-sc-tb-header .dt-sc-tb-title:before,
.dt-sc-pr-tb-col.type2 .dt-sc-tb-content:before,
.dt-sc-pr-tb-col.type2 .dt-sc-tb-content li .highlight,
.dt-sc-pr-tb-col.type2:hover .dt-sc-price:before,
.dt-sc-pr-tb-col.type2.selected .dt-sc-price:before,
.dt-sc-pr-tb-col.type2:hover .dt-sc-buy-now a,
.dt-sc-pr-tb-col:hover .dt-sc-pricing-buy-now a i.fa,
.dt-sc-pr-tb-col:hover .dt-sc-pricing-buy-now a i.fa:after,
.dt-sc-pr-tb-col.type3:hover .dt-sc-pricing-icon,
.dt-sc-pr-tb-col.type3.selected .dt-sc-pricing-icon,
.dt-sc-pr-tb-col.selected .dt-sc-pricing-buy-now a i.fa,
.dt-sc-pr-tb-col.selected .dt-sc-pricing-buy-now a i.fa:after,
.dt-sc-pr-tb-col .dt-sc-pricing-buy-now a,
.dt-sc-pr-tb-col.type3.selected:before,
.dt-sc-pr-tb-col.type3.selected:after,
ul.dt-sc-pricing-table.type4 ul.dt-sc-pricing-table-type4-item.selected:before,
.dt-sc-pr-tb-col
  ul.dt-sc-pricing-table-type4-item.selected
  .dt-sc-pricing-buy-now
  a
  i.fa,
.dt-sc-pr-tb-col
  ul.dt-sc-pricing-table-type4-item.selected
  .dt-sc-pricing-buy-now
  a
  i.fa:after,
.dt-sc-pr-tb-col.type5 .dt-sc-tb-price:after,
.dt-sc-pr-tb-col.type5.selected,
.dt-sc-pr-tb-col.type5:hover,
.dt-sc-pr-tb-col.type1.classic:hover,
.dt-sc-pr-tb-col.type1.classic.selected,
.dt-sc-pr-tb-col.type5:hover:after,
.dt-sc-pr-tb-col.type5.selected:after {
  background-color: #fcd21c;
}
.dt-sc-hr-timeline-section.type1:before,
.dt-sc-hr-timeline-section.type1
  .dt-sc-hr-timeline
  .dt-sc-hr-timeline-content:after,
.dt-sc-hr-timeline-section.type1 .dt-sc-hr-timeline-wrapper:before,
.dt-sc-hr-timeline-section.type1 .dt-sc-hr-timeline-wrapper:after,
.dt-sc-hr-timeline-section.type2 .dt-sc-hr-timeline-content h3:before,
.dt-sc-hr-timeline-section.type2
  .dt-sc-hr-timeline:hover
  .dt-sc-hr-timeline-thumb:before,
.dt-sc-timeline-item.slick-current span {
  background-color: #fcd21c;
}
.dt-sc-timeline-section.type2:before,
.dt-sc-timeline-section.type3 .dt-sc-timeline .dt-sc-timeline-content h2:before,
.dt-sc-timeline-section.type4 .dt-sc-timeline .dt-sc-timeline-content h2:before,
.dt-sc-timeline-section.type4
  .dt-sc-timeline:hover
  .dt-sc-timeline-thumb:before,
.dt-sc-timeline-section.dt-sc-vc-special:before,
.dt-sc-timeline-section.type3.custom-style
  .dt-sc-timeline:hover
  .dt-sc-timeline-icon-wrapper
  > span {
  background-color: #fcd21c;
}
.dt-sc-image-caption.type2:hover .dt-sc-image-title:before,
.dt-sc-image-caption.type4:hover .dt-sc-button,
.dt-sc-event-image-caption:hover,
.dt-sc-image-caption.type7.dt-sc-rounded .dt-sc-image-content h3:after,
.dt-sc-image-caption.type7.dt-sc-rounded .dt-sc-image-wrapper,
.dt-sc-image-caption.type8 .dt-sc-image-wrapper .icon-wrapper span,
.dt-sc-image-caption.type9 .dt-sc-image-wrapper .icon-wrapper:after,
.dt-sc-image-caption:hover .dt-sc-image-content a {
  background-color: #fcd21c;
}
.dt-sc-tabs-horizontal-frame-container.type4
  ul.dt-sc-tabs-horizontal-frame
  > li
  > a.current
  > span:after,
.dt-sc-tabs-horizontal-frame-container.type5
  ul.dt-sc-tabs-horizontal-frame
  > li
  > a.current,
.type8 ul.dt-sc-tabs-horizontal-frame > li > a.current,
.type8 ul.dt-sc-tabs-horizontal-frame > li > a:hover,
.dt-sc-tabs-horizontal-frame-container.type6
  ul.dt-sc-tabs-horizontal-frame:after,
.dt-sc-tabs-horizontal-frame-container.type6
  ul.dt-sc-tabs-horizontal-frame:before {
  background-color: #fcd21c;
}
.dt-sc-tabs-vertical-frame-container.type3
  ul.dt-sc-tabs-vertical-frame
  > li
  > a:hover,
.dt-sc-tabs-vertical-frame-container.type3
  ul.dt-sc-tabs-vertical-frame
  > li
  > a.current,
.dt-sc-tabs-vertical-frame-container.type4
  ul.dt-sc-tabs-vertical-frame
  > li
  > a.current:before {
  background-color: #fcd21c;
}
.dt-sc-toggle-frame h5.dt-sc-toggle-accordion.active a:before,
h5.dt-sc-toggle-accordion.active a:before,
.dt-sc-toggle-frame h5.dt-sc-toggle.active a:before,
h5.dt-sc-toggle.active a:before,
.type2 .dt-sc-toggle-frame h5.dt-sc-toggle-accordion.active,
.type2 .dt-sc-toggle-frame h5.dt-sc-toggle.active,
.dt-sc-toggle-frame-set.type2 > h5.dt-sc-toggle-accordion.active:after,
.dt-sc-toggle-icon {
  background-color: #fcd21c;
}
.dt-sc-video-wrapper .video-overlay-inner a,
.dt-sc-video-item:hover .dt-sc-vitem-detail,
.dt-sc-video-item.active .dt-sc-vitem-detail,
.type2 .dt-sc-video-item:hover,
.type2 .dt-sc-video-item.active,
.nicescroll-rails.dt-sc-skin {
  background-color: #fcd21c;
}
.live-chat a,
.dt-bmi-inner-content tbody th,
.dt-bmi-inner-content tbody tr:nth-child(2n + 1) th,
.dt-sc-menu .menu-categories a:before,
.hotel-search-container form input[type="submit"]:hover,
.hotel-search-container .selection-box:after,
.dt-sc-training-details-overlay,
.custom-navigation .vc_images_carousel .vc_carousel-indicators li,
.dt-sc-doctors.style1 .dt-sc-doctors-thumb-wrapper .dt-sc-button,
.dt-sc-doctors-single
  .dt-sc-doctors.style1
  .dt-sc-doctors-details
  ul.dt-sc-sociable
  li
  a,
.dt-sc-procedure-item:hover,
.dt-sc-fitness-procedure-sorting a,
ul.dt-sc-vertical-nav > li.active > a,
ul.time-table > li,
ul.time-slots > li a:hover,
.dt-sc-available-times ul.time-slots,
#wpsl-search-btn,
#wpsl-stores li > p span,
#wpsl-stores li > p,
#wpsl-stores li > p ~ .wpsl-directions,
.dt-sc-toggle-advanced-options span {
  background-color: #fcd21c;
}
.dt-sc-title.with-right-border-decor > *,
.dt-sc-title.with-right-border-decor > *:after,
.dt-sc-title.with-right-border-decor > *:before,
img.pentagon,
.pentagon .vc_single_image-wrapper,
div[class*="dt-sc-curvy"]:before,
div[class*="custom-style"].dt-sc-icon-box.type5 .icon-wrapper,
.vc_row[data-vc-full-width].dt-sc-paper-pattern-bottom:before,
.dt-sc-team-shortcode-image,
div[class*="custom-style-2"].dt-sc-icon-box.type3 .icon-wrapper,
div[class*="custom-style-2"].dt-sc-icon-box.type3 .icon-content h6:before,
.dt-sc-circular-icon-set,
.dt-sc-icon-box.type8.animated-icons .icon-wrapper:after,
.dt-sc-icon-box.type12.custom-style .icon-content:after,
div[class*="custom-style"].dt-sc-icon-box.type13 .icon-content h5:before,
.dt-sc-icon-box.type2.custom-style .icon-content h5,
.dt-sc-radial-skin-highlight .wpb_wrapper:after,
.carousel_items.dt-sc-custom-carousel .dt-carousel-pagination a.selected:before,
.carousel_items.dt-sc-custom-carousel .dt-carousel-pagination a:hover:before,
.dt-sc-counter.type1.custom-style .icon-wrapper,
.dt-sc-special-testimonial.custom-style
  .dt-sc-special-testimonial-images-holder.slick-slider
  ul.slick-dots
  li.slick-active
  button:before,
.dt-sc-special-testimonial.custom-style
  .dt-sc-special-testimonial-images-holder.slick-slider
  ul.slick-dots
  li:hover
  button:before,
.dt-sc-special-testimonial.custom-style
  .dt-sc-special-testimonial-images-holder
  .dt-sc-testimonial-image.slick-current,
.dt-sc-special-testimonial.custom-style
  .dt-sc-special-testimonial-images-holder
  .dt-sc-testimonial-image:hover,
.dt-sc-team-shortcode-image:before,
.video-section > .vc_column-inner > .wpb_wrapper:before,
.dt-sc-timeline-section.type3.custom-style:before,
.dt-sc-timeline-section.type3.custom-style
  .dt-sc-timeline
  .dt-sc-timeline-content:after,
.dt-sc-timeline-section.type3.custom-style
  .dt-sc-timeline:hover
  .dt-sc-timeline-image-wrapper
  img,
.dt-sc-timeline-section.type3.custom-style
  .dt-sc-timeline:hover
  .dt-sc-timeline-content:before,
.dt-sc-hr-timeline-section.type2.custom-style .dt-sc-hr-timeline-thumb,
.dt-sc-hr-timeline-section.type2.custom-style
  .dt-sc-hr-timeline-content
  h3:before,
.dt-sc-hr-timeline-section.type2.custom-style
  .dt-sc-hr-timeline-content
  h3:after,
.dt-sc-hr-timeline-section.type2.custom-style .dt-sc-hr-timeline-thumb:before {
  background-color: #fcd21c;
}
#footer .wpcf7-form.bottom-bordered input[type="submit"]:hover,
#footer .wpcf7-form.bottom-bordered button:hover,
#footer .wpcf7-form.bottom-bordered input[type="button"]:hover,
#footer .wpcf7-form.bottom-bordered input[type="reset"]:hover,
#footer
  .footer-22
  .dt-sc-newsletter-section.type6
  .dt-sc-subscribe-frm
  input[type="submit"]:hover,
.footer-26 .tagcloud a:hover {
  border-color: #fcd21c;
}
.widget .dt-sc-newsletter-section.boxed,
.widget
  .dt-sc-newsletter-section.boxed
  .dt-sc-subscribe-frm
  input[type="submit"],
.tagcloud a:hover,
.dt-sc-dark-bg .tagcloud a:hover,
.secondary-sidebar .type3 .widgettitle,
.secondary-sidebar .type6 .widgettitle,
.secondary-sidebar .type13 .widgettitle:before,
.secondary-sidebar .type14 .widgettitle,
.secondary-sidebar .type16 .widgettitle {
  border-color: #fcd21c;
}
.pagination ul li span,
.pagination ul li a:hover,
.blog-entry .entry-social-share .share,
.dt-sc-post-entry.entry-cover-layout .blog-entry.sticky,
.dt-sc-post-entry.entry-cover-layout .blog-entry .entry-social-share .share,
.dt-sc-content-overlay-style.dt-sc-post-entry
  .blog-entry
  div.entry-tags
  a:hover,
.dt-sc-classic-style.dt-sc-post-entry
  .blog-entry.sticky
  > div.entry-meta-group
  > div,
.dt-sc-classic-overlay-style.dt-sc-post-entry
  .blog-entry
  > .entry-categories
  > a:hover,
.dt-sc-overlay-style.dt-sc-post-entry.entry-list-layout
  .blog-entry
  .entry-thumb,
.dt-sc-overlay-style.dt-sc-post-entry.entry-list-layout.entry-right-thumb
  .blog-entry
  .entry-thumb,
.dt-sc-overlay-style.dt-sc-post-entry.entry-grid-layout
  .blog-entry
  > div.entry-thumb,
.dt-sc-minimal-style.dt-sc-post-entry.entry-list-layout .blog-entry.sticky,
.dt-sc-minimal-style.dt-sc-post-entry.entry-list-layout
  .blog-entry.sticky
  > div.entry-meta-group,
.dt-sc-title-overlap-style.dt-sc-post-entry
  .blog-entry.sticky
  > div.entry-title:after,
.dt-sc-title-overlap-style.dt-sc-post-entry
  .blog-entry:hover
  > div.entry-title:after,
div[class*="metagroup-elements-filled"] div[class*="entry-"],
div[class*="meta-elements-filled"],
div[class*="metagroup-elements-boxed"] div[class*="entry-"]:hover,
div[class*="meta-elements-boxed"]:hover,
.blog-entry .entry-button a.dt-sc-button,
.page-link > span,
.page-link > a:hover {
  border-color: #fcd21c;
}
.dt-sc-portfolio-sorting a.active-sort,
.dt-sc-portfolio-sorting a:hover,
.portfolio.type7 .image-overlay .links a:before {
  border-color: #fcd21c;
}
.dt-sc-colored-big-buttons,
.dt-sc-button.rounded-border.black:hover,
.dt-sc-button.bordered.black:hover,
.dt-sc-button.bordered:hover {
  border-color: #fcd21c;
}
.dt-sc-sociable.rounded-border li a:hover,
.dt-sc-dark-bg .dt-sc-sociable.rounded-border li a:hover,
.dt-sc-dark-bg .dt-sc-sociable.square-border li a:hover,
.dt-sc-sociable.diamond-square-border li:hover,
.diamond-narrow-square-border li:before {
  border-color: #fcd21c;
}
.dt-sc-team .dt-sc-team-social.diamond-square-border li:hover,
.dt-sc-team-social.hexagon-border li:hover,
.dt-sc-team-social.hexagon-border li:hover:before,
.dt-sc-team-social.hexagon-border li:hover:after,
.dt-sc-team-social.rounded-border li a:hover,
.dt-sc-team-social.square-border li a:hover,
.dt-sc-team.team_rounded_border.rounded:hover .dt-sc-team-thumb:before,
.dt-sc-team.flip-details-on-hover .dt-sc-team-thumb {
  border-color: #fcd21c;
}
.dt-sc-testimonial.type5 .dt-sc-testimonial-quote,
.dt-sc-testimonial-images li.selected div,
.dt-sc-testimonial-wrapper .dt-sc-testimonial-bullets a:hover,
.dt-sc-testimonial-wrapper .dt-sc-testimonial-bullets a.active,
.dt-sc-testimonial-wrapper .dt-sc-testimonial-bullets a.active:before,
.dt-sc-testimonial-wrapper .dt-sc-testimonial-bullets a.active:hover:before,
.dt-sc-testimonial.type5 .dt-sc-testimonial-author img,
blockquote.type3:before,
blockquote.type3:after,
.dt-sc-testimonial.type7 .dt-sc-testimonial-quote blockquote cite,
.dt-sc-testimonial.type7:hover .dt-sc-testimonial-author span,
.dt-sc-testimonial.type7 .dt-sc-testimonial-quote blockquote cite:after {
  border-color: #fcd21c;
}
ul.dt-sc-tabs-horizontal > li > a.current,
ul.dt-sc-tabs-vertical > li > a.current,
.dt-sc-tabs-vertical-frame-container.type3
  ul.dt-sc-tabs-vertical-frame
  > li
  > a:hover,
.dt-sc-tabs-vertical-frame-container.type3
  ul.dt-sc-tabs-vertical-frame
  > li
  > a.current,
.dt-sc-tabs-horizontal-frame-container.type6
  ul.dt-sc-tabs-horizontal-frame
  > li:before,
.dt-sc-tabs-vertical-frame-container.type4
  ul.dt-sc-tabs-vertical-frame
  > li
  > a.current:before,
.dt-sc-tabs-horizontal-frame-container.type6
  ul.dt-sc-tabs-horizontal-frame
  > li
  > a.current,
.dt-sc-tabs-horizontal-frame-container.type6
  ul.dt-sc-tabs-horizontal-frame
  > li
  > a:hover {
  border-color: #fcd21c;
}
.dt-sc-timeline-section.type3.custom-style:after,
.dt-sc-timeline-section.type3.custom-style
  .dt-sc-timeline
  .dt-sc-timeline-content:before {
  border-color: #fcd21c;
}
.dt-sc-tabs-horizontal-frame-container.type6
  ul.dt-sc-tabs-horizontal-frame
  > li
  > a:before {
  border-top-color: #fcd21c;
}
.type2 .dt-sc-toggle-frame h5.dt-sc-toggle-accordion.active,
.type2 .dt-sc-toggle-frame h5.dt-sc-toggle.active {
  border-color: #fcd21c;
}
.dt-sc-hr-timeline-section.type1
  .dt-sc-hr-timeline
  .dt-sc-hr-timeline-content:before,
.dt-sc-timeline-section.type2 .dt-sc-timeline-image-wrapper,
.dt-sc-timeline-section.type2 .dt-sc-timeline .dt-sc-timeline-content:after,
.dt-sc-timeline-section.type2:after {
  border-color: #fcd21c;
}
.dt-sc-counter.type3 .icon-wrapper:before,
.dt-sc-counter.type3.diamond-square,
.dt-sc-counter.type5:hover:before,
.dt-sc-counter.type5:hover:after,
.dt-sc-counter.type6,
.dt-sc-counter.type6 .dt-sc-couter-icon-holder:before {
  border-color: #fcd21c;
}
.dt-sc-contact-info.type2:hover,
.dt-sc-contact-info.type4,
.last .dt-sc-contact-info.type4,
.dt-sc-contact-info.type9:hover {
  border-color: #fcd21c;
}
.dt-sc-icon-box.type5.no-icon .icon-content h6,
.dt-sc-icon-box.type5.no-icon,
.dt-sc-icon-box.type10,
.dt-sc-icon-box.type10 .icon-wrapper:before,
.dt-sc-icon-box.type3.dt-sc-diamond:hover .icon-wrapper:after,
.dt-sc-icon-box.type11:before,
.dt-sc-icon-box.type16 .icon-wrapper {
  border-color: #fcd21c;
}
.dt-sc-image-caption.type2 .dt-sc-image-title:before,
.dt-sc-image-caption.type4,
.dt-sc-image-caption.type4:hover .dt-sc-button,
.dt-sc-icon-box.type10:hover .icon-wrapper:before,
.dt-sc-image-caption.type5:hover img,
.dt-sc-image-caption.type5:hover .dt-sc-image-wrapper:before {
  border-color: #fcd21c;
}
.dt-skin-primary-border,
.dt-sc-title.with-right-border-decor h2:before,
.dt-sc-pr-tb-col.type2 .dt-sc-tb-header:before,
.dt-sc-newsletter-section.type2 .dt-sc-subscribe-frm input[type="text"],
.dt-sc-newsletter-section.type2 .dt-sc-subscribe-frm input[type="email"],
.dt-sc-text-with-icon.border-bottom,
.dt-sc-text-with-icon.border-right,
.dt-sc-hexagons li:hover,
.dt-sc-hexagons li:hover:before,
.dt-sc-hexagons li:hover:after,
.dt-sc-hexagons li,
.dt-sc-hexagons li:before,
.dt-sc-hexagons li .dt-sc-hexagon-overlay:before,
.dt-sc-hexagons li:after,
.dt-sc-hexagons li .dt-sc-hexagon-overlay:after,
.dt-sc-single-hexagon,
.dt-sc-single-hexagon:before,
.dt-sc-single-hexagon .dt-sc-single-hexagon-overlay:before,
.dt-sc-single-hexagon:after,
.dt-sc-single-hexagon .dt-sc-single-hexagon-overlay:after,
.dt-sc-single-hexagon:hover,
.dt-sc-single-hexagon:hover:before,
.dt-sc-single-hexagon:hover:after,
.carousel-arrows a:hover,
.vc_custom_carousel .slick-slider .slick-dots,
.vc_custom_carousel .slick-slider:before,
.dt-sc-team-navigation .dt-sc-team-pager-prev:before,
.dt-sc-team-navigation .dt-sc-team-pager-next:before,
ul.dt-sc-vertical-nav,
ul.dt-sc-vertical-nav > li:first-child > a,
.dt-sc-loading:before,
.side-navigation.type2 ul.side-nav,
.side-navigation.type2 ul.side-nav li,
.side-navigation.type2 ul.side-nav li ul,
.dt-sc-images-carousel li,
.dt-sc-newsletter-section.type1
  .dt-sc-subscribe-frm
  input[type="submit"]:hover {
  border-color: #fcd21c;
}
.dt-sc-triangle-wrapper:hover .dt-sc-triangle-content:before,
.dt-sc-pr-tb-col.type2 .dt-sc-tb-content:after,
.dt-sc-content-with-hexagon-shape:after,
.type7 ul.dt-sc-tabs-horizontal-frame > li > a.current:before,
.type7 ul.dt-sc-tabs-horizontal-frame > li > a.current:after,
.skin-highlight
  .dt-sc-tabs-horizontal-frame-container.type6
  ul.dt-sc-tabs-horizontal-frame
  > li
  > a:before,
.dt-sc-doctors-filter .selection-box:before,
.dt-sc-newsletter-section.type2 .input-content:hover:before,
.dt-sc-newsletter-section.type2 .input-content:focus:before,
.dt-sc-newsletter-section.type2 .input-content:hover:before,
.dt-sc-newsletter-section.type2 .input-content:focus:before,
.dt-sc-overlay-iii-style.dt-sc-post-entry.entry-cover-layout
  .blog-entry
  .entry-format
  a:after,
.under-construction.type2 .dt-sc-counter-wrapper {
  border-top-color: #fcd21c;
}
.dt-sc-up-arrow:before,
.dt-sc-image-caption .dt-sc-image-wrapper .icon-wrapper:before,
.dt-sc-triangle-wrapper.alter:hover .dt-sc-triangle-content:before,
.dt-sc-content-with-hexagon-shape:before,
.dt-sc-tabs-horizontal-frame-container.type3
  ul.dt-sc-tabs-horizontal-frame
  > li
  > a.current,
.dt-sc-tabs-horizontal-frame-container.type4
  ul.dt-sc-tabs-horizontal-frame
  > li
  > a.current,
#footer .footer-22 .dt-sc-newsletter-section.type6,
.dt-sc-tabs-horizontal-frame-container.type6 ul.dt-sc-tabs-horizontal-frame,
.dt-sc-image-caption.type5 .dt-sc-image-content,
.dt-sc-image-caption.type5 .dt-sc-image-content:after,
.dt-sc-newsletter-section.type2 .input-content:hover:after,
.dt-sc-newsletter-section.type2 .input-content:focus:after,
.dt-sc-newsletter-section.type2 .input-content:hover:after,
.dt-sc-newsletter-section.type2 .input-content:focus:after {
  border-bottom-color: #fcd21c;
}
.type3 .dt-sc-toggle-frame .dt-sc-toggle-content,
.dt-sc-tabs-vertical-frame-container.type3
  ul.dt-sc-tabs-vertical-frame
  > li
  > a.current:before,
.dt-sc-event-image-caption:hover .dt-sc-image-content:before,
.side-navigation.type2 ul.side-nav > li.current_page_item > a:after,
.side-navigation.type2 ul.side-nav > li > ul > li.current_page_item > a:after,
.dt-sc-special-testimonial-images-holder.slick-slider button.slick-next:before,
.dt-sc-newsletter-section.type2 .input-content:hover:after,
.dt-sc-newsletter-section.type2 .input-content:focus:after,
.dt-sc-newsletter-section.type2 .input-content:hover:after,
.dt-sc-newsletter-section.type2 .input-content:focus:after {
  border-left-color: #fcd21c;
}
.dt-sc-newsletter-section.type2 .input-content:hover:before,
.dt-sc-newsletter-section.type2 .input-content:focus:before,
.dt-sc-newsletter-section.type2 .input-content:hover:before,
.dt-sc-newsletter-section.type2 .input-content:focus:before {
  border-right-color: #fcd21c;
}
#footer .footer-22.slope-bg.dt-sc-skin-highlight:before,
#footer .footer-22 .dt-sc-newsletter-section.type6,
.dt-sc-map-form-holder .map-form-switcher .switcher-toggle:before,
.dt-sc-special-testimonial-images-holder.slick-slider button.slick-prev:before {
  border-right-color: #fcd21c;
}
.dt-sc-attorney-sorting,
.dt-sc-menu-sorting a.active-sort,
.dt-sc-menu .image-overlay .price,
.hotel-search-container form input[type="submit"] {
  border-color: #fcd21c;
}
.dt-sc-pr-tb-col .dt-sc-pricing-buy-now a,
.dt-sc-pr-tb-col.type3 .dt-sc-pr-tb-col-wrapper,
.dt-sc-pr-tb-col.type3 ul.dt-sc-tb-content li,
ul.dt-sc-pricing-table.type4,
ul.dt-sc-pricing-table.type4 .dt-sc-tb-title,
ul.dt-sc-pricing-table.type4 > li.dt-sc-pr-tb-col {
  border-color: #fcd21c;
}
.error404 .type2 a.dt-sc-back,
.error404 .type4 .error-box:before,
.error404 .type4 .dt-sc-newsletter-section input[type="submit"],
.error404 .type8 .dt-go-back {
  background-color: #fcd21c;
}
.error404 .type2 h2,
.error404 .type8 h2,
.error404 .type8 .dt-go-back:hover i {
  color: #fcd21c;
}

#tribe-bar-views .tribe-bar-views-list .tribe-bar-views-option a:hover,
#tribe-bar-views
  .tribe-bar-views-list
  .tribe-bar-views-option.tribe-bar-active
  a:hover,
#tribe-bar-form .tribe-bar-submit input[type="submit"],
#tribe-bar-views .tribe-bar-views-list li.tribe-bar-active a,
.tribe-events-calendar thead th,
#tribe-events-content .tribe-events-tooltip h4,
.tribe-events-calendar td.tribe-events-present div[id*="tribe-events-daynum-"],
.tribe-events-read-more,
#tribe-events .tribe-events-button,
.tribe-events-button,
.tribe-events-calendar
  td.tribe-events-present
  div[id*="tribe-events-daynum-"]
  > a,
.tribe-events-back > a,
#tribe_events_filters_toggle {
  background-color: #fcd21c;
}
.tribe-events-list .tribe-events-event-cost span {
  border-color: #fcd21c;
}
.tribe-grid-header,
.tribe-grid-allday .tribe-events-week-allday-single,
.tribe-grid-body .tribe-events-week-hourly-single {
  background-color: #fcd21c;
}
.type1.tribe_events .event-image-wrapper .event-datetime > span,
.type3.tribe_events .event-date,
.event-meta-tab ul.dt-sc-tabs-horizontal-frame > li > a {
  background-color: #fcd21c;
}
.type1 .event-schedule,
.type1.tribe_events .nav-top-links a:hover,
.type1.tribe_events .event-image-wrapper .event-datetime > i,
.type1.tribe_events .event-image-wrapper .event-venue > i,
.type1.tribe_events h4 a,
.type2.tribe_events .date-wrapper p span,
.type2.tribe_events h4 a,
.type3.tribe_events .right-calc a:hover,
.type3.tribe_events .tribe-events-sub-nav li a:hover,
.type3.tribe_events .tribe-events-sub-nav li a span,
.type4.tribe_events .data-wrapper p span,
.type4.tribe_events .data-wrapper p i,
.type4.tribe_events .event-organize h4 a,
.type4.tribe_events .event-venue h4 a,
.type5.tribe_events .event-details h3,
.type5.tribe_events .event-organize h3,
.type5.tribe_events .event-venue h3,
.type5.tribe_events .data-wrapper p span,
.data-wrapper p i,
.type5.tribe_events .event-organize h4 a,
.type5.tribe_events .event-venue h4 a {
  color: #fcd21c;
}
.dt-sc-event.type1 .dt-sc-event-thumb p,
.dt-sc-event.type1 .dt-sc-event-meta:before,
.dt-sc-event.type2:hover .dt-sc-event-meta,
.dt-sc-event.type3 .dt-sc-event-date,
.dt-sc-event.type3:hover .dt-sc-event-meta {
  background-color: #fcd21c;
}
.dt-sc-event.type4 .dt-sc-event-date:after {
  border-bottom-color: #fcd21c;
}
.dt-sc-event.type1 .dt-sc-event-meta p span,
.dt-sc-event.type1:hover h2.entry-title a,
.dt-sc-event.type3:hover h2.entry-title a,
.dt-sc-event.type4 .dt-sc-event-date span {
  color: #fcd21c;
}
.widget.tribe_mini_calendar_widget
  .tribe-mini-calendar
  thead.tribe-mini-calendar-nav
  td,
.widget.tribe_mini_calendar_widget .tribe-mini-calendar .tribe-events-present,
.widget.tribe_mini_calendar_widget
  .tribe-mini-calendar
  .tribe-events-has-events.tribe-mini-calendar-today,
.tribe-mini-calendar .tribe-events-has-events.tribe-events-present a:hover,
.widget.tribe_mini_calendar_widget
  .tribe-mini-calendar
  td.tribe-events-has-events.tribe-mini-calendar-today
  a:hover,
.dt-sc-dark-bg
  .widget.tribe_mini_calendar_widget
  .tribe-mini-calendar
  .tribe-events-present,
.dt-sc-dark-bg
  .widget.tribe_mini_calendar_widget
  .tribe-mini-calendar
  .tribe-events-has-events.tribe-mini-calendar-today,
.dt-sc-dark-bg
  .tribe-mini-calendar
  .tribe-events-has-events.tribe-events-present
  a:hover,
.dt-sc-dark-bg
  .widget.tribe_mini_calendar_widget
  .tribe-mini-calendar
  td.tribe-events-has-events.tribe-mini-calendar-today
  a:hover {
  background-color: #fcd21c;
}
.widget.tribe_mini_calendar_widget
  .tribe-mini-calendar
  thead.tribe-mini-calendar-nav
  td {
  border-color: #fcd21c;
}
.widget.tribe-events-countdown-widget .tribe-countdown-text a:hover {
  color: #fcd21c;
}

.dt-inline-modal > h4 {
  background-color: #fcd21c;
}
.dt-skin-secondary-color,
.dt-sc-title.with-two-color-bg h2 strong,
div.dt-sc-posts-meta-group div[class*="entry-"] a,
div[class*="metagroup-"] div[class*="entry-"] a,
div[class*="meta-elements"] a,
.blog-single-entry.post-custom-classic
  div[class*="metagroup-"]
  div[class*="entry-"]
  a:hover,
.page-link a,
.page-link a > span,
.commentlist li.comment .reply a,
.dt-sc-button.fully-rounded-border:hover,
.dt-sc-button.rounded-border:hover,
.dt-sc-button.bordered:hover,
.dt-sc-button.with-icon.icon-right.type3:hover span,
.dt-sc-contact-info.type6 a,
.dt-sc-contact-info.type3 a,
.dt-sc-icon-box.type5.no-icon-bg .icon-content h5,
.dt-sc-icon-box.type5.no-icon .icon-content h5,
.dt-sc-pr-tb-col.type5.selected .dt-sc-tb-title h5,
.dt-sc-pr-tb-col.type5:hover .dt-sc-tb-title h5,
.dt-sc-image-caption:hover .dt-sc-image-content a,
.dt-sc-testimonial.type4 .dt-sc-testimonial-author cite small,
.dt-sc-dark-bg .dt-sc-title.with-right-border-decor > *,
.dt-sc-button.animated.white,
.dt-sc-icon-box.type1.custom-style:hover:after,
.dt-sc-icon-box.type1.custom-style p a,
div[class*="custom-style"].dt-sc-icon-box.type3 .icon-wrapper span,
.dt-sc-image-caption.type5.dt-sc-student-info h3 a,
.dt-sc-counter.type3.custom-style-2 .icon-wrapper span,
.dt-sc-counter.type3.custom-style-2 .dt-sc-counter-number,
.dt-sc-special-testimonial.custom-style
  .dt-sc-special-testimonial-images-holder
  .dt-sc-testimonial-author
  cite,
.dt-sc-special-testimonial.custom-style
  .dt-sc-special-testimonial-images-holder
  .dt-sc-testimonial-image.slick-current
  .dt-sc-testimonial-author
  cite,
.dt-sc-special-testimonial.custom-style
  .dt-sc-special-testimonial-images-holder
  .dt-sc-testimonial-image:hover
  .dt-sc-testimonial-author
  cite,
.dt-sc-special-testimonial.custom-style
  .dt-sc-special-testimonial-images-holder
  .dt-sc-testimonial-image.slick-current
  .dt-sc-testimonial-author
  cite
  small,
.dt-sc-special-testimonial.custom-style
  .dt-sc-special-testimonial-images-holder
  .dt-sc-testimonial-image:hover
  .dt-sc-testimonial-author
  cite
  small,
.dt-sc-special-testimonial.custom-style
  .dt-sc-special-testimonial-details-holder
  .dt-sc-testimonial-description,
.vc-hoverbox-wrapper.custom-style.type-1 .vc-hoverbox-block-inner p > a,
.dt-sc-toggle-group-set.custom-style h5.dt-sc-toggle a,
.dt-sc-team-shortcode-image a,
.dt-sc-team-shortcode-description .dt-sc-team-social li a,
.video-section
  > .vc_column-inner
  > .wpb_wrapper
  > .wpb_text_column
  p:last-child
  a.dt-sc-button,
.dt-sc-hr-timeline-section.type2.custom-style
  .dt-sc-hr-timeline:hover
  .dt-sc-hr-timeline-content
  h3,
.blog-entry .entry-button a.dt-sc-button,
.dt-sc-icon-box.type12.custom-style .icon-wrapper span,
.dt-sc-counter.type1.custom-style .icon-wrapper span {
  color: #090909;
}
.dt-skin-secondary-bg,
div[class*="dt-skin-secondary-bg-opaque"]:not(.ult-vc-hide-row):before,
div[class*="dt-skin-secondary-bg-opaque"] .upb_row_bg:before,
section[class*="dt-skin-secondary-bg-opaque"]:before,
.dt-skin-secondary-bg.extend-bg-fullwidth-left:after,
.dt-skin-secondary-bg.extend-bg-fullwidth-right:after,
.mz-blog .comments a:hover,
.mz-blog div.vc_gitem-post-category-name:hover,
.dt-sc-infinite-portfolio-load-more:hover,
.dt-sc-button.filled:hover,
.dt-sc-button.with-icon.icon-right.type1:hover,
.dt-sc-icon-box.type3:hover .icon-wrapper span,
.dt-sc-newsletter-section.type2 .dt-sc-subscribe-frm input[type="submit"]:hover,
.skin-highlight .dt-sc-testimonial.type6 .dt-sc-testimonial-author:before,
.skin-highlight .dt-sc-testimonial.type6:after,
.dt-sc-team-social.rounded-square li a:hover,
.dt-sc-video-wrapper .video-overlay-inner a:hover,
.side-navigation.type2 ul.side-nav li a:before,
.side-navigation.type2 ul.side-nav > li.current_page_item > a:before,
.side-navigation.type2 ul.side-nav > li > ul > li.current_page_item > a:before,
.side-navigation.type2
  ul.side-nav
  > li
  > ul
  > li
  > ul
  > li.current_page_item
  > a:before,
.dt-sc-icon-box.type5:hover .icon-wrapper:before,
.dt-sc-image-caption.type2:hover,
.dt-sc-team.hide-social-role-show-on-hover:hover .dt-sc-team-details h5:after,
.animated-twin-lines:before,
.dt-sc-pr-tb-col.type5 .dt-sc-tb-title:after,
.dt-sc-pr-tb-col.type1.classic:hover .dt-sc-buy-now a,
.dt-sc-contact-info.type4 h6:after,
.dt-sc-contact-info.type8 span:after,
.dt-sc-contact-info.type4:hover span:after,
.dt-sc-button:hover,
.dt-sc-button.with-icon.icon-left.type2 span:after,
.dt-sc-button.with-icon.icon-left.type2.rounded-border:hover span:after,
.dt-sc-button.with-icon.icon-left.type2.bordered:hover span:after,
.dt-sc-button.with-icon.icon-left.type2.fully-rounded-border:hover span:after,
.dt-sc-pr-tb-col.type1.classic.selected .dt-sc-buy-now .dt-sc-button,
.dt-sc-pr-tb-col.type5.selected .dt-sc-tb-price:after,
.dt-sc-pr-tb-col.type5:hover .dt-sc-tb-price:after,
.dt-sc-image-caption.type8:hover .dt-sc-image-wrapper .icon-wrapper span,
.dt-sc-tabs-horizontal-frame-container.type6.selected
  ul.dt-sc-tabs-horizontal-frame
  > li:before,
.dt-sc-button.animated.white:hover,
.pentagon.with-outline:before,
.dt-sc-icon-box.type1.custom-style:hover,
div[class*="custom-style"].dt-sc-icon-box.type3 .icon-wrapper:after,
div[class*="custom-style"].dt-sc-icon-box.type3:hover .icon-wrapper,
div[class*="custom-style"].dt-sc-icon-box.type5 .icon-wrapper:after,
div[class*="custom-style"].dt-sc-icon-box.type5:hover .icon-wrapper,
.dt-sc-circular-icon-set
  div[class*="custom-style-2"].dt-sc-icon-box.type5:before,
.dt-sc-circular-icon-set
  div[class*="custom-style-2"].dt-sc-icon-box.type5:hover
  .icon-wrapper,
div[class*="custom-style"]:not(.custom-style-2).dt-sc-icon-box.type13:hover,
div[class*="custom-style-2"].dt-sc-icon-box.type13 p a span:before,
.dt-sc-icon-box.type2.custom-style:hover .icon-content h5,
.vc_row.dt-sc-fullwidth-iconboxes
  > .wpb_column
  .dt-sc-icon-box.type2.custom-style:hover
  .icon-content
  h5,
.vc_row.dt-sc-fullwidth-iconboxes
  > .wpb_column
  .dt-sc-icon-box.type2.custom-style.alignleft:not(:last-child):before,
.vc_row.dt-sc-fullwidth-iconboxes
  > .wpb_column
  .dt-sc-icon-box.type2.custom-style.alignright:not(:last-child):before,
.dt-sc-radial-skin-highlight .wpb_wrapper:before,
.carousel_items.dt-sc-custom-carousel .dt-carousel-navigation a.prev-arrow,
.carousel_items.dt-sc-custom-carousel .dt-carousel-navigation a.next-arrow,
.carousel_items.dt-sc-custom-carousel .dt-carousel-pagination a,
.carousel_items.dt-sc-custom-carousel .dt-carousel-pagination a:before,
.dt-sc-counter.type1.custom-style:hover .icon-wrapper,
.dt-sc-special-testimonial.custom-style
  .dt-sc-special-testimonial-images-holder.slick-slider
  ul.slick-dots
  li
  button:before,
.dt-sc-toggle-group-set.custom-style h5.dt-sc-toggle a:before,
.dt-sc-toggle-group-set.custom-style h5.dt-sc-toggle a:after,
.dt-sc-tabs-horizontal-container.custom-style
  ul.dt-sc-tabs-horizontal
  > li
  > a:before,
.dt-sc-team-shortcode-description .dt-sc-team-social li a:hover,
.dt-sc-hr-timeline-section.type2.custom-style
  .dt-sc-hr-timeline:hover
  .dt-sc-hr-timeline-thumb,
.dt-sc-skin-highlight .dt-sc-icon-box.type12.custom-style .icon-content:after,
.woocommerce-checkout #payment div.form-row.place-order > #place_order:hover {
  background-color: #090909;
}
.dt-skin-secondary-border,
.dt-sc-contact-info.type5 .dt-sc-contact-icon,
.dt-sc-contact-info.type5 .dt-sc-contact-icon:before,
.dt-sc-contact-info.type5 .dt-sc-contact-icon:after,
.dt-sc-image-caption.type2:hover .dt-sc-image-title,
.dt-sc-sociable.hexagon-with-border li,
.dt-sc-sociable.hexagon-with-border li:before,
.dt-sc-sociable.hexagon-with-border li:after,
.side-navigation.type5 ul.side-nav,
.side-navigation.type5 ul.side-nav li a,
.side-navigation.type5 ul.side-nav li ul,
.dt-sc-button.fully-rounded-border,
.dt-sc-button.rounded-border,
.dt-sc-button.bordered,
.dt-sc-button.bordered:hover,
.dt-sc-button.fully-rounded-border,
.dt-sc-button.fully-rounded-border:hover,
.dt-sc-button.animated.white,
.dt-sc-button.animated.white:hover,
.dt-sc-icon-box.type2.custom-style:hover .icon-content h5:before,
.vc_row.dt-sc-fullwidth-iconboxes
  > .wpb_column
  .dt-sc-icon-box.type2.custom-style
  .icon-content
  h5:before,
.dt-sc-hr-timeline-section.type2.custom-style .dt-sc-hr-timeline-thumb:after,
.dt-sc-hr-timeline-section.type2.custom-style:before,
.dt-sc-special-testimonial.custom-style
  .dt-sc-special-testimonial-images-holder.slick-slider
  ul.slick-dots
  li
  button:after,
.dt-sc-counter.type1.custom-style .icon-wrapper:before,
.carousel_items.dt-sc-custom-carousel .dt-carousel-pagination a:after {
  border-color: #090909;
}
.error404 .type2 a.dt-sc-back:hover,
.error404 .type4 .dt-sc-newsletter-section input[type="submit"]:hover {
  background-color: #090909;
}
#item-header-content #item-meta > #item-buttons .group-button:hover,
#buddypress .activity-list li.load-more a:hover,
#buddypress .activity-list li.load-newest a:hover {
  background-color: #090909;
}
#bbpress-forums #subscription-toggle a.subscription-toggle:hover,
.bbp-submit-wrapper #bbp_topic_submit:hover {
  background-color: #090909;
}
#tribe-bar-form .tribe-bar-submit input[type="submit"]:hover,
.tribe-events-read-more:hover,
#tribe-events .tribe-events-button:hover,
.tribe-events-button:hover,
.tribe-events-back > a:hover,
.datepicker thead tr:first-child th:hover,
.datepicker tfoot tr th:hover,
#tribe_events_filters_toggle:hover {
  background-color: #090909;
}
.tribe-grid-header .tribe-week-today {
  background-color: #090909;
}

.mobile-menu ul.dt-primary-nav li > a:hover,
.mobile-menu ul.dt-primary-nav li:hover > a,
.mobile-menu ul.dt-primary-nav li ul.sub-menu li > a:hover,
.mobile-menu ul.dt-primary-nav li ul.sub-menu li:hover > a,
.mobile-menu ul.dt-primary-nav li.current-menu-item > a,
.mobile-menu ul.dt-primary-nav li.current-page-item > a,
.mobile-menu ul.dt-primary-nav li.current-menu-ancestor > a,
.mobile-menu ul.dt-primary-nav li.current-page-ancestor > a,
.mobile-menu ul.dt-primary-nav li.current_menu_item > a,
.mobile-menu ul.dt-primary-nav li.current_page_item > a,
.mobile-menu ul.dt-primary-nav li.current_menu_ancestor > a,
.mobile-menu ul.dt-primary-nav li.current_page_ancestor > a,
.mobile-menu ul.dt-primary-nav li ul.sub-menu li.current-menu-item > a,
.mobile-menu ul.dt-primary-nav li ul.sub-menu li.current-page-item > a,
.mobile-menu ul.dt-primary-nav li ul.sub-menu li.current-menu-ancestor > a,
.mobile-menu ul.dt-primary-nav li ul.sub-menu li.current-page-ancestor > a,
.mobile-menu ul.dt-primary-nav li ul.sub-menu li.current_menu_item > a,
.mobile-menu ul.dt-primary-nav li ul.sub-menu li.current_page_item > a,
.mobile-menu ul.dt-primary-nav li ul.sub-menu li.current_menu_ancestor > a,
.mobile-menu ul.dt-primary-nav li ul.sub-menu li.current_page_ancestor > a,
.mobile-menu ul.dt-primary-nav li ul.children li.current-menu-item > a,
.mobile-menu ul.dt-primary-nav li ul.children li.current-page-item > a,
.mobile-menu ul.dt-primary-nav li ul.children li.current-menu-ancestor > a,
.mobile-menu ul.dt-primary-nav li ul.children li.current-page-ancestor > a,
.mobile-menu ul.dt-primary-nav li ul.children li.current_menu_item > a,
.mobile-menu ul.dt-primary-nav li ul.children li.current_page_item > a,
.mobile-menu ul.dt-primary-nav li ul.children li.current_menu_ancestor > a,
.mobile-menu ul.dt-primary-nav li ul.children li.current_page_ancestor > a,
.dt-skin-tertiary-color,
.dt-sc-contact-info.type2 a:hover,
a,
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover,
.dt-sc-image-caption.type5.dt-sc-student-info:hover .dt-sc-image-content h3 a,
#footer .footer-copyright .menu-links li a:hover,
#footer .footer-copyright .copyright-left a:hover,
#footer .dt-sc-dark-bg .recent-posts-widget li .entry-meta a:hover,
#footer .dt-sc-dark-bg .entry-title h4 a:hover,
#footer .dt-sc-dark-bg a:hover,
.left-header-footer .dt-sc-sociable.filled li a,
.footer-widgets a:hover,
#footer a:hover,
.blog-single-entry.post-overlap
  > .entry-thumb
  .entry-overlap
  .entry-bottom-details
  > *
  a:hover {
  color: #eab317;
}
.dt-skin-tertiary-bg,
div[class*="dt-skin-tertiary-bg-opaque"]:not(.ult-vc-hide-row):before,
div[class*="dt-skin-tertiary-bg-opaque"] .upb_row_bg:before,
section[class*="dt-skin-tertiary-bg-opaque"]:before,
.dt-skin-tertiary-bg.extend-bg-fullwidth-left:after,
.dt-skin-tertiary-bg.extend-bg-fullwidth-right:after,
.dt-sc-triangle-title:before,
.dt-sc-icon-box.type10 .icon-wrapper:after,
.side-navigation.type1 ul.side-nav > li.current_page_item > a,
.side-navigation.type1 ul.side-nav > li > ul > li.current_page_item > a,
.side-navigation.type1
  ul.side-nav
  > li
  > ul
  > li
  > ul
  > li.current_page_item
  > a,
.dt-sc-shop-single-sticky-addtocart-section a.added_to_cart.wc-forward:hover {
  background-color: #eab317;
}
.dt-skin-tertiary-border {
  border-color: #eab317;
}
.footer-22 .footer-copyright.vc_row {
  border-top-color: #eab317;
}
body:not(.block-editor-page):not(.wp-core-ui) .main-title-section h1,
body:not(.block-editor-page):not(.wp-core-ui) h1.simple-title {
  font-family: Poppins;
  font-size: 34px;
  font-weight: 800;
  letter-spacing: 0.5px;
  text-transform: none;
  color: #ffffff;
}
div.breadcrumb a {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 800;
  letter-spacing: 0px;
  text-transform: none;
  color: #ffffff;
}
body:not(.block-editor-page):not(.wp-core-ui) {
  font-family: Source Sans Pro;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-transform: none;
  color: #000000;
}
body:not(.block-editor-page):not(.wp-core-ui) h1 {
  font-family: Poppins;
  font-size: 44px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: none;
  color: #090909;
}
body:not(.block-editor-page):not(.wp-core-ui) h2 {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: none;
  color: #090909;
}
body:not(.block-editor-page):not(.wp-core-ui) h3 {
  font-family: Poppins;
  font-size: 34px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: none;
  color: #090909;
}
body:not(.block-editor-page):not(.wp-core-ui) h4 {
  font-family: Poppins;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: none;
  color: #000000;
}
body:not(.block-editor-page):not(.wp-core-ui) h5 {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: none;
  color: #090909;
}
body:not(.block-editor-page):not(.wp-core-ui) h6 {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: none;
  color: #090909;
}
div.footer-widgets h3.widgettitle,
#footer h3.widgettitle {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 34px;
  text-align: left;
  text-transform: none;
  color: #2b2b2b;
}
#footer,
.footer-copyright,
div.footer-widgets .widget {
  font-family: Source Sans Pro;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 34px;
  text-align: left;
  text-transform: none;
  color: #090909;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 100;
  src: local("Poppins Thin Italic"), local("Poppins-ThinItalic"),
  url(/wp-content/uploads/2019/10/pxiAyp8kv8JHgFVrJJLmE3tG.woff)
  format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 200;
  src: local("Poppins ExtraLight Italic"), local("Poppins-ExtraLightItalic"),
  url(/wp-content/uploads/2019/10/pxiDyp8kv8JHgFVrJJLmv1plEw.woff)
  format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 300;
  src: local("Poppins Light Italic"), local("Poppins-LightItalic"),
  url(/wp-content/uploads/2019/10/pxiDyp8kv8JHgFVrJJLm21llEw.woff)
  format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 400;
  src: local("Poppins Italic"), local("Poppins-Italic"),
  url(/wp-content/uploads/2019/10/pxiGyp8kv8JHgFVrJJLedA.woff) format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 500;
  src: local("Poppins Medium Italic"), local("Poppins-MediumItalic"),
  url(/wp-content/uploads/2019/10/pxiDyp8kv8JHgFVrJJLmg1hlEw.woff)
  format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 600;
  src: local("Poppins SemiBold Italic"), local("Poppins-SemiBoldItalic"),
  url(/wp-content/uploads/2019/10/pxiDyp8kv8JHgFVrJJLmr19lEw.woff)
  format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 700;
  src: local("Poppins Bold Italic"), local("Poppins-BoldItalic"),
  url(/wp-content/uploads/2019/10/pxiDyp8kv8JHgFVrJJLmy15lEw.woff)
  format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 800;
  src: local("Poppins ExtraBold Italic"), local("Poppins-ExtraBoldItalic"),
  url(/wp-content/uploads/2019/10/pxiDyp8kv8JHgFVrJJLm111lEw.woff)
  format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  font-style: italic;
  font-weight: 900;
  src: local("Poppins Black Italic"), local("Poppins-BlackItalic"),
  url(/wp-content/uploads/2019/10/pxiDyp8kv8JHgFVrJJLm81xlEw.woff)
  format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 100;
  src: local("Poppins Thin"), local("Poppins-Thin"),
  url(/wp-content/uploads/2019/10/pxiGyp8kv8JHgFVrLPTedA.woff) format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 200;
  src: local("Poppins ExtraLight"), local("Poppins-ExtraLight"),
  url(/wp-content/uploads/2019/10/pxiByp8kv8JHgFVrLFj_V1g.woff) format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: local("Poppins Light"), local("Poppins-Light"),
  url(/wp-content/uploads/2019/10/pxiByp8kv8JHgFVrLDz8V1g.woff) format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: local("Poppins Regular"), local("Poppins-Regular"),
  url(/wp-content/uploads/2019/10/pxiEyp8kv8JHgFVrFJM.woff) format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: local("Poppins Medium"), local("Poppins-Medium"),
  url(/wp-content/uploads/2019/10/pxiByp8kv8JHgFVrLGT9V1g.woff) format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"),
  url(/wp-content/uploads/2019/10/pxiByp8kv8JHgFVrLEj6V1g.woff) format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: local("Poppins Bold"), local("Poppins-Bold"),
  url(/wp-content/uploads/2019/10/pxiByp8kv8JHgFVrLCz7V1g.woff) format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  src: local("Poppins ExtraBold"), local("Poppins-ExtraBold"),
  url(/wp-content/uploads/2019/10/pxiByp8kv8JHgFVrLDD4V1g.woff) format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  src: local("Poppins Black"), local("Poppins-Black"),
  url(/wp-content/uploads/2019/10/pxiByp8kv8JHgFVrLBT5V1g.woff) format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 200;
  src: local("Source Sans Pro ExtraLight Italic"),
  local("SourceSansPro-ExtraLightItalic"),
  url(/wp-content/uploads/2019/09/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZYokRdo.woff)
  format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 300;
  src: local("Source Sans Pro Light Italic"), local("SourceSansPro-LightItalic"),
  url(/wp-content/uploads/2019/09/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZMkhdo.woff)
  format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 400;
  src: local("Source Sans Pro Italic"), local("SourceSansPro-Italic"),
  url(/wp-content/uploads/2019/09/6xK1dSBYKcSV-LCoeQqfX1RYOo3qPa7j.woff)
  format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 600;
  src: local("Source Sans Pro SemiBold Italic"),
  local("SourceSansPro-SemiBoldItalic"),
  url(/wp-content/uploads/2019/09/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZY4lBdo.woff)
  format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 700;
  src: local("Source Sans Pro Bold Italic"), local("SourceSansPro-BoldItalic"),
  url(/wp-content/uploads/2019/09/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZclRdo.woff)
  format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 900;
  src: local("Source Sans Pro Black Italic"), local("SourceSansPro-BlackItalic"),
  url(/wp-content/uploads/2019/09/6xKwdSBYKcSV-LCoeQqfX1RYOo3qPZZklxdo.woff)
  format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 200;
  src: local("Source Sans Pro ExtraLight"), local("SourceSansPro-ExtraLight"),
  url(/wp-content/uploads/2019/09/6xKydSBYKcSV-LCoeQqfX1RYOo3i94_Akw.woff)
  format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 300;
  src: local("Source Sans Pro Light"), local("SourceSansPro-Light"),
  url(/wp-content/uploads/2019/09/6xKydSBYKcSV-LCoeQqfX1RYOo3ik4zAkw.woff)
  format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"),
  url(/wp-content/uploads/2019/09/6xK3dSBYKcSV-LCoeQqfX1RYOo3aPA.woff)
  format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  src: local("Source Sans Pro SemiBold"), local("SourceSansPro-SemiBold"),
  url(/wp-content/uploads/2019/09/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rAkw.woff)
  format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"),
  url(/wp-content/uploads/2019/09/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vAkw.woff)
  format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 900;
  src: local("Source Sans Pro Black"), local("SourceSansPro-Black"),
  url(/wp-content/uploads/2019/09/6xKydSBYKcSV-LCoeQqfX1RYOo3iu4nAkw.woff)
  format("woff");
  font-display: swap;
}

// Owly custom inline

.vc_custom_1575631676548 {
  margin-bottom: -20px !important;
  background-color: #201169 !important;
}
.vc_custom_1580457070965 {
  background-color: #201169 !important;
}
.vc_custom_1575712153269 {
  padding-right: 35px !important;
  padding-left: 35px !important;
}
.vc_custom_1580457924920 {
  padding-right: 35px !important;
  padding-left: 35px !important;
}
.vc_custom_1575712723397 {
  padding-right: 35px !important;
  padding-left: 35px !important;
}
.vc_custom_1580458008506 {
  padding-right: 35px !important;
  padding-left: 35px !important;
}
div#dt-1575631758496-d9036aaf-a97b img {
  width: 100px;
}
@media only screen and (max-width: 727px) {
  div#dt-1575631758496-d9036aaf-a97b img {
    width: 125px;
  }
}
div[id="1580458027323-a3283f03-6064"] {
  height: 20px;
}
@media (max-width: 767px) {
  div[id="1580458027323-a3283f03-6064"] {
    height: 50px;
  }
}
div#dt-1580457312532-5eeae2f9-281a img {
 width: 110px;
}
@media only screen and (max-width: 727px) {
  div#dt-1580457312532-5eeae2f9-281a img {
    width: 125px;
  }
}
div#dt-1580457390742-09000a6b-590e ul.dt-primary-nav > li > a {
  font-size: 18px;
  color: #090909;
}
div#dt-1580457390742-09000a6b-590e ul.dt-primary-nav > li:hover > a,
div#dt-1580457390742-09000a6b-590e ul.dt-primary-nav > li.current-menu-item > a,
div#dt-1580457390742-09000a6b-590e ul.dt-primary-nav > li.current-page-item > a,
div#dt-1580457390742-09000a6b-590e
  ul.dt-primary-nav
  > li.current-menu-ancestor
  > a,
div#dt-1580457390742-09000a6b-590e
  ul.dt-primary-nav
  > li.current-page-ancestor
  > a,
div#dt-1580457390742-09000a6b-590e ul.dt-primary-nav > li.current_menu_item > a,
div#dt-1580457390742-09000a6b-590e ul.dt-primary-nav > li.current_page_item > a,
div#dt-1580457390742-09000a6b-590e
  ul.dt-primary-nav
  > li.current_menu_ancestor
  > a,
div#dt-1580457390742-09000a6b-590e
  ul.dt-primary-nav
  > li.current_page_ancestor
  > a {
  color: #090909;
}
div#dt-1580457390742-09000a6b-590e
  ul
  > li:not(.has-mega-menu)
  ul.sub-menu
  li
  > a {
  font-size: 16px;
  color: #090909;
}
div#dt-1580457390742-09000a6b-590e
  ul
  > li:not(.has-mega-menu)
  ul.sub-menu
  li:hover
  > a,
div#dt-1580457390742-09000a6b-590e
  ul
  > li:not(.has-mega-menu)
  ul.sub-menu
  li.current-menu-item
  > a,
div#dt-1580457390742-09000a6b-590e
  ul
  > li:not(.has-mega-menu)
  ul.sub-menu
  li.current-page-item
  > a,
div#dt-1580457390742-09000a6b-590e
  ul
  > li:not(.has-mega-menu)
  ul.sub-menu
  li.current-menu-ancestor
  > a,
div#dt-1580457390742-09000a6b-590e
  ul
  > li:not(.has-mega-menu)
  ul.sub-menu
  li.current-page-ancestor
  > a,
div#dt-1580457390742-09000a6b-590e
  ul
  > li:not(.has-mega-menu)
  ul.sub-menu
  li.current_menu_item
  > a,
div#dt-1580457390742-09000a6b-590e
  ul
  > li:not(.has-mega-menu)
  ul.sub-menu
  li.current_page_item
  > a,
div#dt-1580457390742-09000a6b-590e
  ul
  > li:not(.has-mega-menu)
  ul.sub-menu
  li.current_menu_ancestor
  > a,
div#dt-1580457390742-09000a6b-590e
  ul
  > li:not(.has-mega-menu)
  ul.sub-menu
  li.current_page_ancestor
  > a {
  color: #090909;
  background-color: #fcd21c;
}
@media only screen and (max-width: 1199px) {
  div#dt-1580457390742-09000a6b-590e {
    display: none;
  }
  div#dt-1580457390742-09000a6b-590e-mobile {
    display: block;
  }
  div#dt-1580457390742-09000a6b-590e-mobile .menu-trigger > span {
    color: #000000;
  }
  div#dt-1580457390742-09000a6b-590e-mobile .menu-trigger > i {
    color: #000000;
  }
}
@media only screen and (min-width: 1200px) {
  div#dt-1580457390742-09000a6b-590e {
    display: inline-block;
  }
  div#dt-1580457390742-09000a6b-590e.center {
    display: table;
  }
  div#dt-1580457390742-09000a6b-590e-mobile,
  div#dt-1580457390742-09000a6b-590e li.go-back,
  div#dt-1580457390742-09000a6b-590e li.see-all {
    display: none;
  }
}
div[id="1574497523500-397e9744-5809"] {
  height: 170px;
}
@media (max-width: 767px) {
  div[id="1574497523500-397e9744-5809"] {
    height: 0px;
  }
}
div[id="1574497523720-53dba92d-4ffb"] {
  height: 170px;
}
@media (max-width: 767px) {
  div[id="1574497523720-53dba92d-4ffb"] {
    height: 0px;
  }
}
div[id="1574497523959-377d4d09-1cfc"] {
  height: 60px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  div[id="1574497523959-377d4d09-1cfc"] {
    height: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  div[id="1574497523959-377d4d09-1cfc"] {
    height: 50px;
  }
}
@media (max-width: 767px) {
  div[id="1574497523959-377d4d09-1cfc"] {
    height: 50px;
  }
}
@media (max-width: 767px) {
  div[id="1545389825325-e242d30f-2e4b"] {
    height: 50px;
  }
}
@media (max-width: 767px) {
  div[id="1545389844910-1c15208f-6e0d"] {
    height: 0px;
  }
}
@media (max-width: 767px) {
  div[id="1574503028787-571c597e-95a1"] {
    height: 100px;
  }
}
@media (max-width: 767px) {
  div[id="1574503187261-2c55e320-e998"] {
    height: 50px;
  }
}
div[id="1570618621544-ef65c9b7-524b"] {
  height: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  div[id="1570618621544-ef65c9b7-524b"] {
    height: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  div[id="1570618621544-ef65c9b7-524b"] {
    height: 50px;
  }
}
@media (max-width: 767px) {
  div[id="1570618621544-ef65c9b7-524b"] {
    height: 50px;
  }
}
div[id="1571829203851-5f6accbe-3d82"] {
  height: 68px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  div[id="1571829203851-5f6accbe-3d82"] {
    height: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  div[id="1571829203851-5f6accbe-3d82"] {
    height: 50px;
  }
}
@media (max-width: 767px) {
  div[id="1571829203851-5f6accbe-3d82"] {
    height: 50px;
  }
}
div[id="1571809498698-8c263360-9370"] {
  height: 20px;
}
div[id="1545312408049-4e9f9b2e-d775"] {
  height: 44px;
}
div[id="1570274231766-ebd18938-edd8"] {
  height: 50px;
}
@media (max-width: 767px) {
  div[id="1570083633594-3dcf6a5f-a3c6"] {
    height: 100px;
  }
}
div[id="1570083633782-963bf0c0-4b27"] {
  height: 90px;
}
div[id="1574659060846-a75d47c4-ff04"] {
  height: 50px;
}
div[id="1574659062861-d226a010-5918"] {
  height: 90px;
}
@media (max-width: 767px) {
  div[id="1570087739172-59256d0d-1295"] {
    height: 100px;
  }
}
div[id="1574661121884-992fd229-4e6f"] {
  height: 30px;
}
@media (max-width: 767px) {
  div[id="1574661121884-992fd229-4e6f"] {
    height: 100px;
  }
}
div[id="1574661122477-7f42df02-e261"] {
  height: 28px;
}
div[id="1574661122700-def93315-b1d4"] {
  height: 34px;
}
div[id="1574661122934-7e8e289a-e255"] {
  height: 16px;
}
div[id="1574661123277-bdc46b83-1b2e"] {
  height: 110px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  div[id="1574661123277-bdc46b83-1b2e"] {
    height: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  div[id="1574661123277-bdc46b83-1b2e"] {
    height: 50px;
  }
}
@media (max-width: 767px) {
  div[id="1574661123277-bdc46b83-1b2e"] {
    height: 50px;
  }
}
div[id="1571646283060-a4423236-65d2"] {
  height: 90px;
}
div[id="1570600710077-319bb6fb-35ea"] {
  height: 20px;
}
div[id="1574664037007-e98828b2-658c"] {
  height: 20px;
}
div[id="1574664820352-805b2b1c-d67b"] {
  height: 68px;
}
div[id="1545818235951-44ab4d03-7259"] {
  height: 30px;
}
div[id="1570600869132-2bc99724-a42b"] {
  height: 20px;
}
.vc_custom_1574417845589 {
  margin-right: -30px !important;
}
.vc_custom_1574417871485 {
  margin-left: -40px !important;
  @include media-query($on-palm) {
    margin-left: 0 !important;
  }
}
.vc_custom_1574417885893 {
  margin-left: -40px !important;
}
.vc_custom_1571998176762 {
  margin-right: -30px !important;
}
div[id="1570605500009-aa383b64-86b7"] {
  height: 85px;
}
div[id="1571826948578-fda1248c-9c1f"] {
  height: 16px;
}
div[id="1570605770343-8a78ced2-96a1"] {
  height: 12px;
}
@media (max-width: 767px) {
  div[id="1570605814636-6b88533d-0d1d"] {
    height: 30px;
  }
}
div[id="1570605841587-454c2cde-c7e8"] {
  height: 12px;
}
@media (max-width: 767px) {
  div[id="1570605889063-d72dbc6f-0830"] {
    height: 30px;
  }
}
div[id="1570605954444-8cf6f7d6-bc47"] {
  height: 12px;
}
ul#dt-1570605967911-f1c86679-fe9f > li > a > i {
  color: #090909;
}
ul#dt-1570605967911-f1c86679-fe9f > li > a > .dt-icon-default:after,
ul#dt-1570605967911-f1c86679-fe9f[data-default-style="bordered"][data-default-shape="hexagon"]
  li
  a
  > .dt-icon-default
  > span:before,
ul#dt-1570605967911-f1c86679-fe9f[data-default-style="bordered"][data-default-shape="hexagon"]
  li
  a
  > .dt-icon-default
  > span:after,
ul#dt-1570605967911-f1c86679-fe9f[data-default-style="bordered"][data-default-shape="hexagon-alt"]
  li
  a
  > .dt-icon-default
  > span:before,
ul#dt-1570605967911-f1c86679-fe9f[data-default-style="bordered"][data-default-shape="hexagon-alt"]
  li
  a
  > .dt-icon-default
  > span:after {
  border-color: #090909;
}
ul#dt-1570605967911-f1c86679-fe9f > li > a:hover > i {
  color: #fcd21c;
}
ul#dt-1570605967911-f1c86679-fe9f > li > a > .dt-icon-hover:before,
ul#dt-1570605967911-f1c86679-fe9f[data-hover-style="filled"][data-hover-shape="hexagon"]
  li
  a
  > .dt-icon-hover
  > span:before,
ul#dt-1570605967911-f1c86679-fe9f[data-hover-style="filled"][data-hover-shape="hexagon"]
  li
  a
  > .dt-icon-hover
  > span:after,
ul#dt-1570605967911-f1c86679-fe9f[data-hover-style="filled"][data-hover-shape="hexagon-alt"]
  li
  a
  > .dt-icon-hover
  > span:before,
ul#dt-1570605967911-f1c86679-fe9f[data-hover-style="filled"][data-hover-shape="hexagon-alt"]
  li
  a
  > .dt-icon-hover
  > span:after {
  background-color: #090909;
}
ul#dt-1570605967911-f1c86679-fe9f > li > a > .dt-icon-hover:after,
ul#dt-1570605967911-f1c86679-fe9f[data-hover-style="bordered"][data-hover-shape="hexagon"]
  li
  a
  > .dt-icon-hover
  > span:before,
ul#dt-1570605967911-f1c86679-fe9f[data-hover-style="bordered"][data-hover-shape="hexagon"]
  li
  a
  > .dt-icon-hover
  > span:after,
ul#dt-1570605967911-f1c86679-fe9f[data-hover-style="bordered"][data-hover-shape="hexagon-alt"]
  li
  a
  > .dt-icon-hover
  > span:before,
ul#dt-1570605967911-f1c86679-fe9f[data-hover-style="bordered"][data-hover-shape="hexagon-alt"]
  li
  a
  > .dt-icon-hover
  > span:after {
  border-color: #090909;
}
div[id="1570605500988-65c4763e-89aa"] {
  height: 22px;
}
div[id="1570605501064-6e147a11-58dc"] {
  height: 10px;
}
div[id="1574417184980-6574c346-22ec"] {
  height: 85px;
}
div[id="1575632936118-a72b017b-21fb"] {
  height: 16px;
}
@media (max-width: 767px) {
  div[id="1574417185048-90f68fa4-fd16"] {
    height: 30px;
  }
}
div[id="1574417185154-3e603e7e-9d71"] {
  height: 12px;
}
@media (max-width: 767px) {
  div[id="1574417185205-d77d4e42-d454"] {
    height: 30px;
  }
}
div[id="1574417185280-e21f30f4-5e91"] {
  height: 12px;
}
@media (max-width: 767px) {
  div[id="1574417185361-25a46269-835a"] {
    height: 30px;
  }
}
div[id="1574417185440-f7f0f2bf-7f18"] {
  height: 12px;
}
ul#dt-1574417185465-03772744-bb9c > li > a > i {
  color: #090909;
}
ul#dt-1574417185465-03772744-bb9c > li > a > .dt-icon-default:after,
ul#dt-1574417185465-03772744-bb9c[data-default-style="bordered"][data-default-shape="hexagon"]
  li
  a
  > .dt-icon-default
  > span:before,
ul#dt-1574417185465-03772744-bb9c[data-default-style="bordered"][data-default-shape="hexagon"]
  li
  a
  > .dt-icon-default
  > span:after,
ul#dt-1574417185465-03772744-bb9c[data-default-style="bordered"][data-default-shape="hexagon-alt"]
  li
  a
  > .dt-icon-default
  > span:before,
ul#dt-1574417185465-03772744-bb9c[data-default-style="bordered"][data-default-shape="hexagon-alt"]
  li
  a
  > .dt-icon-default
  > span:after {
  border-color: #090909;
}
ul#dt-1574417185465-03772744-bb9c > li > a:hover > i {
  color: #fcd21c;
}
ul#dt-1574417185465-03772744-bb9c > li > a > .dt-icon-hover:before,
ul#dt-1574417185465-03772744-bb9c[data-hover-style="filled"][data-hover-shape="hexagon"]
  li
  a
  > .dt-icon-hover
  > span:before,
ul#dt-1574417185465-03772744-bb9c[data-hover-style="filled"][data-hover-shape="hexagon"]
  li
  a
  > .dt-icon-hover
  > span:after,
ul#dt-1574417185465-03772744-bb9c[data-hover-style="filled"][data-hover-shape="hexagon-alt"]
  li
  a
  > .dt-icon-hover
  > span:before,
ul#dt-1574417185465-03772744-bb9c[data-hover-style="filled"][data-hover-shape="hexagon-alt"]
  li
  a
  > .dt-icon-hover
  > span:after {
  background-color: #090909;
}
ul#dt-1574417185465-03772744-bb9c > li > a > .dt-icon-hover:after,
ul#dt-1574417185465-03772744-bb9c[data-hover-style="bordered"][data-hover-shape="hexagon"]
  li
  a
  > .dt-icon-hover
  > span:before,
ul#dt-1574417185465-03772744-bb9c[data-hover-style="bordered"][data-hover-shape="hexagon"]
  li
  a
  > .dt-icon-hover
  > span:after,
ul#dt-1574417185465-03772744-bb9c[data-hover-style="bordered"][data-hover-shape="hexagon-alt"]
  li
  a
  > .dt-icon-hover
  > span:before,
ul#dt-1574417185465-03772744-bb9c[data-hover-style="bordered"][data-hover-shape="hexagon-alt"]
  li
  a
  > .dt-icon-hover
  > span:after {
  border-color: #090909;
}
div[id="1574417185528-4853f7fe-c4e3"] {
  height: 22px;
}
div[id="1574417185587-07c00b8e-af9e"] {
  height: 10px;
}
