.testimonial-review {
  .ult-item-wrap {
    margin: 1em;
    @include media-query($on-palm) {
      margin: 1em;
    }
  }

  .testimonial-item {
    display: grid;
    grid-template-columns: 1fr 3fr;
    @include media-query($on-palm) {
      grid-template-columns: 1fr;
    }
  }

  .image-container,
  .quote-container {
    display: grid;
    place-items: center;
  }

  .image-container {
    padding-right: 10px;
    border-right: 3px solid lighten($h-color, 10%);
    @include media-query($on-palm) {
      border: none;
      padding: 0;
      margin-top: 1em;
    }
  }
  .slick-dots {
    @include media-query($on-palm) {
      bottom: 10px !important;
    }
  }
  .shadow-sm {
    box-shadow: 0 0 15px #ddd;
  }
  .border-light {
    border: 1px solid #ddd;
  }
  .rounded-lg {
    border-radius: 8px;
  }
  .reverse-image {
    justify-self: end;
    stroke: $h-color;
    stroke-width: 2px;
    @include media-query($on-palm) {
      display: none !important;
    }
  }
  .py-5 {
    @include media-query($on-palm) {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
  }

  img {
    position: relative;
    display: inline-block !important;
    border-radius: 50%;
    box-shadow: 0 0 0 2px white, 0 0 0 5px $brand-color !important;
    @include media-query($on-palm) {
      height: 60px;
      width: 60px;
      margin-bottom: 0.5em;
    }
  }

  .quote {
    padding: 1em;
    font-size: 0.8em !important;
    line-height: 1.5 !important;
    @include media-query($on-palm) {
      font-size: 0.6em !important;
      padding: 1em 0;
    }
  }
  .name {
    font-weight: bold !important;
    color: $h-color !important;
    margin-bottom: 0;
    @include media-query($on-palm) {
      font-size: 0.7em !important;
    }
  }
  .designation {
    margin: 0;
    font-size: 0.6em;
    color: #555 !important;
  }
}
