.videos-container {
  display: grid;
  grid-template-columns: 2fr 3fr;

  @include media-query($on-palm) {
    display: block;
    margin-left: 10px;
    margin-right: 10px;
  }

  .video-info {
    grid-column: span 1;
    display: grid;
    place-items: center;
    h2 {
      font-size: 2em !important;
      text-align: center;
      max-width: 16ch;
      color: #555 !important;
      @include media-query($on-palm) {
        font-size: 1.2em !important;
      }
      span {
        color: $h-color;
      }
    }
  }

  .video-slider {
    display: grid;
    height: 420px;
    @include media-query($on-palm) {
      height: 320px;
    }
  }

  iframe.video {
    width: 900px;
    height: 420px;
    @include media-query($on-palm) {
      width: 100vw;
      height: 320px;
    }
  }

  .slide-navs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
  }
  .relative {
    position: relative;
  }
  .play-button {
    position: absolute;
    display: block;
    color: #f9f9f9;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    &:hover {
      color: $brand-color;
    }
  }
  #next,
  #prev {
    display: grid;
    place-items: center;
    cursor: pointer;
    border: 1px solid #eee;
    padding: 0.5em 1em;
    @include media-query($on-palm) {
      padding: 0.25em 0.5em;
    }
    &:hover {
      background-color: #ddd;
    }
    svg {
      color: #555;
    }
  }
}
